@use 'styles/colors.module.scss' as colors;
@use 'styles/constants.module.scss' as constants;
@use 'styles/utils.module.scss' as utils;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/mixins.module.scss' as mixins;
@use 'components/organisms/BiddingTable/biddingTable.module.scss' as
  biddingTable;

.table {
  padding: 0;
  border: none;
  table-layout: fixed;
  width: auto;

  &__wrapper {
    @include mixins.table-scroll;
  }

  &__row {
    @include mixins.flex-container($align-items: center);
    height: fit-content;
    border-bottom: constants.$DEFAULT_BORDER_SIZE solid
      colors.$borders-strokes-border-grey;
  }

  &__header {
    border-bottom: constants.$DEFAULT_BORDER_SIZE solid
      colors.$borders-strokes-border-grey;
    background: colors.$background-tint-grey;
    height: fit-content;
  }

  &__header-row {
    @extend .table__row;
    height: utils.pxToRem(56);

    @include biddingTable.bidding-table-header-modifiers;
  }

  @include breakpoints.media-query-for(constants.$MEDIUM_DESKTOP_BREAKPOINT) {
    width: 100%;
  }
}
