@use 'styles/colors.module.scss' as colors;
@use 'styles/breakpoints.module.scss' as *;
@use 'styles/utils.module.scss' as utils;
@use 'styles/mixins.module.scss' as mixins;
@use '/src/styles/constants.module.scss';

.active-campaings-container {
  @include mixins.flex-container(column, $gap: 1rem);
}

.past-campaigns-container {
  padding-top: 2rem;
  min-height: 21rem;
  @include mixins.flex-container(column, flex-start, $gap: 1rem);

  &--empty {
    padding-top: 2rem;
    min-height: 21rem;
    @include mixins.flex-container(column, flex-start, flex-start, $gap: 1rem);
  }
}

.body {
  display: flex;
  align-self: stretch;
  width: 100%;

  &--regular {
    padding: 3rem 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  &--card {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
  }
}

.section-heading {
  @include mixins.flex-container(row, space-between, center, 2rem);
  min-height: utils.pxToRem(38);
  align-self: stretch;

  &__text {
    flex: 1 0 0;
  }

  &__dropdown {
    height: 2.5rem;
    background-color: colors.$background-tint-grey;
    width: utils.pxToRem(169);
  }
}

.message-frame {
  @include mixins.flex-container(column, null, center, 0.25rem);
  width: utils.pxToRem(362);
  text-align: center;

  @include media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
    width: utils.pxToRem(417.88);
  }
}

.subtext-color {
  color: colors.$text-medium-grey;
}

.btn-container {
  display: flex;
  justify-content: center;
  height: utils.pxToRem(38);

  @include media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
    width: 100%;
    height: 3rem;

    &--header {
      height: utils.pxToRem(38);
    }
  }
}

.btn-content {
  @include mixins.flex-container(row, center, center, 0.38rem);
  width: utils.pxToRem(103);

  &--icon-color {
    color: inherit;
  }

  &--icon-size {
    width: utils.pxToRem(14);
    height: utils.pxToRem(14);

    @include media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      width: 1rem;
      height: 1rem;
    }
  }

  @include media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
    width: utils.pxToRem(148.96);
    gap: 0.5rem;
  }

  &--header {
    width: utils.pxToRem(103);
    height: 1.25rem;
    gap: 0.38rem;
  }

  &--header-icon-size {
    width: utils.pxToRem(14);
    height: utils.pxToRem(14);
  }
}

.filter-bar {
  &__searchbox-container {
    @include media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      width: utils.pxToRem(280);
    }
  }

  &__searchbox {
    background-color: transparent;

    @include media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      height: utils.pxToRem(45);
    }
  }
}
