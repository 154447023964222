@use 'styles/colors.module.scss';
@use 'styles/z-index.module.scss';
@use 'styles/animations.module.scss' as keyframes;

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index.$z-index-modal;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(colors.$interactive-overlay-black, 0.5);
  animation: 0.2s ease-out 0s fadeIn;
}
