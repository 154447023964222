@use 'src/styles/breakpoints.module.scss' as breakpoints;
@use 'src/styles/typekit.module.scss' as typekits;
@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/utils.module.scss' as utils;
@use 'src/styles/constants.module.scss' as constants;

.card-campaign {
  display: flex;

  @include breakpoints.media-query-for('small-tablet') {
    height: utils.pxToRem(251);

    &:hover &__img {
      transform: scale(1.3);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0 1rem 1rem;
    width: utils.pxToRem(247);
    justify-content: space-between;
  }

  &__img-container {
    @include mixins.slope-image;
    width: 55%;
    flex-grow: 1;
    position: relative;
    left: 1px;

    @include breakpoints.media-query-for('small-desktop') {
      top: -1px;
    }

    .card-campaign__img {
      object-fit: cover;
      transition: transform 0.5s;

      position: absolute;
      width: 100%;
      height: 100%;

      @include breakpoints.media-query-for('small-tablet') {
        height: calc(100% + 1px);
      }
    }
  }

  &__donations {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem 1rem;
    border-top: constants.$BORDER-GREY;

    &-ended {
      padding: 0.5rem 1rem;
    }

    @include breakpoints.media-query-for('small-tablet') {
      min-width: utils.pxToRem(255);
      border: 0;
      padding: 0;
    }

    @include breakpoints.media-query-for('small-desktop') {
      min-width: utils.pxToRem(273);
    }
  }

  &__text {
    @include mixins.flex-container($direction: column, $gap: 0.5rem);
  }

  &__header {
    @include mixins.flex-container($direction: column, $gap: 1rem);
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
