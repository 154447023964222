/* 01 Interactive */

$interactive-kg-blue: #002acf;
$interactive-black: #15191b;
$interactive-overlay-black: #000000;
$interactive-white: #ffffff;
$interactive-light-grey: #ededed;
$interactive-disabled-grey: #e1e1e1;
$interactive-soft-blue: #e6ebff;

/* 02 Text */

$text-black: #15191b;
$text-dark-grey: #424d54;
$text-medium-grey: #747474;
$text-white: #ffffff;
$text-disabled-text: #b8b8b8;
$text-disabled-grey: #bebebe;

/* 03 Background */

$background-white: #ffffff;
$background-tint-grey: #f7f7f7;

/* 04 Borders & Strokes */

$borders-strokes-border-grey: #e3e3e3;

/* 05 System */

$system-error-red: #cc0000;
$system-error-tint-red: #fcf5f5;
$system-success-green: #05af4a;
$system-success-tint-green: #edf6f1;
$system-caution-yellow: #ebb700;
$system-caution-tint-yellow: #fdf8e8;
$system-orange: #eb7100;

/* 06 Special */
