@use '/src/styles/colors.module.scss';
@use '/src/styles/typekit.module.scss';
@use '/src/styles/z-index.module.scss';
@use '/src/styles/mixins.module.scss';
@use '/src/styles/constants.module.scss';

@mixin transition {
  transition:
    opacity 0.3s,
    visibility 0.3s ease-in-out;
}

@mixin hover-focus {
  &:hover,
  &:focus {
    background-color: colors.$background-tint-grey;
  }
}

$icon-height: 5rem;

.actions-menu {
  @include mixins.flex-container($direction: column, $align-items: flex-end);
  width: fit-content;
  position: relative;

  &__icon-container {
    @include hover-focus;
    @include mixins.flex-container(
      $justify-content: center,
      $align-items: center
    );
    width: 3.5rem;
    height: $icon-height;

    &--small {
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }

  &__icon {
    @include mixins.flex-container(row);
    align-self: center;
    cursor: pointer;

    &--small {
      width: 1rem;
      height: 1rem;
    }
  }

  &__actions {
    @include transition;
    @include mixins.flex-container($direction: column);
    position: absolute;
    top: $icon-height;
    right: 0;
    background-color: colors.$interactive-white;
    border: constants.$BORDER-GREY;
    z-index: z-index.$z-index-actions-menu;

    &--hide {
      @include transition;
      opacity: 0;
      visibility: hidden;
    }

    &--small {
      top: 2.9rem;
      border: constants.$BORDER-BLACK;
    }
  }

  &__action {
    @include hover-focus;
    @include typekit.p2;
    @include mixins.flex-container;
    padding: 1rem 2rem;
    text-transform: capitalize;
    background-color: colors.$interactive-white;
    border: none;
    color: colors.$interactive-black;
    z-index: z-index.$z-index-actions-menu-action;

    &:focus {
      border-radius: 0.125rem;
      outline: 6px solid colors.$interactive-light-grey;
      z-index: z-index.$z-index-actions-menu-action-focus;
    }
  }
}
