@use 'styles/typekit.module.scss' as typekit;
@use 'styles/colors.module.scss' as colors;
@use 'styles/utils.module.scss' as utils;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/constants.module.scss' as constants;
@use 'styles/animations.module.scss' as keyframes;

.toggle {
  position: relative;
  transition:
    border-width 0.2s ease-in-out,
    outline 0.2s ease-in-out,
    background 0.2s ease-in-out,
    color 0.2s ease-in-out;
  outline: 0;

  &__icon {
    height: 2rem;
    width: 2rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__currencies {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    margin-top: 0.8rem;
    margin-bottom: 0;
    position: absolute;
    top: 100%;
    right: 0;
    border-radius: utils.pxToRem(2);
    border: utils.pxToRem(1) solid colors.$interactive-black;
    background-color: colors.$interactive-white;
    animation: 0.3s ease-out 0s extendTopToBottom;
    overflow: hidden;

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      margin-top: 1.4rem;
    }

    &:hover {
      cursor: pointer;
    }

    &--closing {
      animation: 0.3s ease-out 0s shrinkTopToBottom forwards;
    }
  }

  &__currency-container {
    display: flex;
    padding: 1rem 1.5rem;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: auto;
    transition: background-color 0.3s ease-in-out;

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      padding: 1rem 2rem;
    }

    &:hover {
      background-color: colors.$interactive-light-grey;
    }

    &--selected {
      background-color: colors.$interactive-light-grey;
    }
  }

  &__currency-image-container {
    height: 1.5rem;
    width: 1.5rem;
    flex-shrink: 0;
  }

  &__currency-image {
    height: auto;
    width: 100%;
  }

  &__currency-info {
    display: flex;
    flex-direction: column;
    min-width: 7.75rem;
    flex-wrap: nowrap;
    width: 100%;
  }

  &__currency-name,
  &__currency-code {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15rem;
  }

  &__currency-code {
    color: colors.$text-dark-grey;
  }
}
