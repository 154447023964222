@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/breakpoints.module.scss' as breakpoints;
@use 'src/styles/colors.module.scss' as colors;

.page-container {
  @include mixins.flex-container(column, center, center, $gap: 1rem);
  width: 100%;
  min-height: 100vh;
  background-color: colors.$background-tint-grey;
}
