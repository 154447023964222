@use 'styles/colors.module.scss';
@use 'styles/breakpoints.module.scss';
@use 'styles/mixins.module.scss';

.container {
  align-self: stretch;
  padding-bottom: 0;

  @include mixins.flex-container(
    column,
    $align-items: flex-start,
    $gap: 1.5rem
  );

  &__details {
    padding: 0 1.5rem 1.5rem;
    width: 100%;

    @include mixins.flex-container(
      column,
      $align-items: flex-start,
      $gap: 1rem
    );

    @include breakpoints.media-query-for('small-desktop') {
      gap: 1.5rem;
    }
  }

  &__auction-info {
    align-self: stretch;

    @include mixins.flex-container(
      $justify-content: space-between,
      $align-items: center
    );
  }
}

.information {
  @include mixins.flex-container(column, $align-items: flex-start, $gap: 1rem);

  @include breakpoints.media-query-for('small-desktop') {
    gap: 0.5rem;
  }
}

.countdown {
  padding-right: 0;
  justify-content: center;
  color: colors.$text-black;

  @include mixins.flex-container(column, $align-items: flex-start);

  &__container {
    width: 6.125rem;
  }
}

.donor {
  @include mixins.flex-container($align-items: center, $gap: 0.5rem);

  &__image {
    border-radius: 50%;
    width: 3.125rem;
    max-height: 100%;
  }

  &__name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.subtitle {
  color: colors.$text-medium-grey;
}

.gallery {
  width: 100%;
  position: relative;

  &__image {
    width: 100%;
    height: 100%;
  }

  &__button {
    position: absolute;
    right: 1rem;
    bottom: 1rem;

    width: auto;
  }
}

.location-icon {
  width: 1rem;
  height: 1rem;
}
