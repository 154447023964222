@use '/src/styles/colors.module.scss';

$DEFAULT_BORDER_SIZE: 1px;
$SMALL_MOBILE_BREAKPOINT: 'small-mobile';
$LARGE_MOBILE_BREAKPOINT: 'large-mobile';
$SMALL_DESKTOP_BREAKPOINT: 'small-desktop';
$MEDIUM_DESKTOP_BREAKPOINT: 'medium-desktop';
$LARGE_DESKTOP_BREAKPOINT: 'large-desktop';

$USER_TABLE_SPECIAL_BREAKPOINT: 'user-table';
$ITEM_TABLE_SPECIAL_BREAKPOINT: 'item-table';

$BORDER-GREY: $DEFAULT_BORDER_SIZE solid colors.$borders-strokes-border-grey;
$BORDER-BLACK: $DEFAULT_BORDER_SIZE solid colors.$interactive-black;
