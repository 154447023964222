@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/typekit.module.scss' as typekit;
@use 'src/styles/mixins.module.scss' as mixins;

.searchbox-container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 100%;
}

.searchbox-body {
  @include mixins.flex-container($align-items: center);
  border: none;
  position: relative;
}

.label {
  order: 1;
  align-self: stretch;
  text-align: left;
  color: colors.$text-medium-grey;
}

.searchbox {
  @include typekit.p2;
  border: none;
  height: 2.875rem;
  width: 100%;
  border-radius: 0.125rem;
  padding: 0.7188rem 2rem;
  padding-left: 3rem;
  border-bottom: solid colors.$interactive-black 0.0625rem;
  background: inherit;
  transition:
    color 0.3s ease-in-out,
    outline 0.3s ease-in-out,
    border 0.3s ease-in-out,
    background 0.3s ease-in-out,
    border-bottom 0.3s ease-in-out;

  &::-webkit-search-cancel-button {
    display: none;
  }

  &:hover:enabled {
    border-bottom: solid colors.$interactive-black 0.125rem;
    cursor: pointer;

    + button span {
      color: colors.$text-black;
    }

    &::placeholder {
      color: colors.$text-black;
    }
  }

  &:focus:enabled {
    border: solid colors.$interactive-black 0.0625rem;
    outline: 0.375rem solid colors.$borders-strokes-border-grey;

    + button span {
      color: colors.$text-black;
    }

    &::placeholder {
      color: transparent;
    }
  }

  &:disabled {
    background: colors.$interactive-disabled-grey;
    border: none;
    cursor: default;

    + label {
      color: colors.$text-disabled-text;
    }

    &::placeholder {
      color: colors.$text-medium-grey;
    }
  }

  &__search-icon {
    position: absolute;
    padding: 0 1rem;
    top: 1rem;
  }

  &__ex-icon {
    @include mixins.flex-container(
      $justify-content: flex-end,
      $align-items: center
    );
    align-self: center;
    position: absolute;
    right: 1rem;
    cursor: pointer;

    svg {
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  &:hover::placeholder {
    color: colors.$text-medium-grey;
  }
}

.non-icon-searchbox {
  order: 2;
  padding-left: 1rem;
}
