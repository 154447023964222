@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/breakpoints.module.scss' as breakpoints;
@use 'src/styles/utils.module.scss' as utils;
@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/typekit.module.scss' as typekit;

@mixin error {
  border: 0.125rem solid colors.$system-error-red;
  background: colors.$system-error-tint-red;
}

@mixin default {
  border: 0.0625rem solid colors.$interactive-black;
  background: colors.$text-white;
}

.input__container {
  @include mixins.flex-container(
    column,
    $align-items: flex-start,
    $gap: 0.25rem
  );
}

.input__label {
  order: 1;
  align-self: stretch;
  text-align: left;
  color: colors.$text-medium-grey;
}

.input {
  @include default();
  order: 2;
  width: 100%;
  height: utils.pxToRem(48);
  border-radius: 0.125rem;
  padding: 0.72rem 1rem;
  color: colors.$text-black;
  outline: 0 solid transparent;
  transition:
    background 0.2s ease-in-out,
    border 0.2s ease-in-out,
    outline 0.2s ease-in-out,
    color 0.2s ease-in-out;
  font-family: typekit.$default-font;
  font-size: 0.875rem;

  &::placeholder {
    color: colors.$text-medium-grey;
    opacity: 1;
  }

  &:hover {
    @include breakpoints.media-query-for('small-desktop') {
      outline: utils.pxToRem(1) solid colors.$interactive-black;
      background: colors.$background-tint-grey;
      cursor: pointer;
    }
  }

  &:focus {
    @include default();
    outline: 0.375rem solid colors.$borders-strokes-border-grey;
    cursor: pointer;

    @include breakpoints.media-query-for('small-desktop') {
      cursor: text;
    }
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:disabled {
    background: colors.$interactive-disabled-grey;
    border: none;
    cursor: default;

    + label {
      color: colors.$text-disabled-text;
    }
  }

  &:disabled::placeholder {
    color: colors.$text-disabled-grey;
  }

  &:invalid,
  &--error {
    @include error();
  }
}

.error-order {
  order: 3;
}
