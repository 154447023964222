@use 'styles/colors.module.scss' as colors;
@use 'styles/mixins.module.scss' as mixins;

.error-message {
  @include mixins.flex-container(row, $align-items: center, $gap: 0.38rem);
  width: 100%;
  height: auto;
  color: colors.$system-error-red;

  &__warning-icon {
    width: 0.75rem;
    height: 0.75rem;
    color: colors.$system-error-red;

    circle {
      color: colors.$system-error-tint-red;
    }
  }
}
