@use '/src/styles/colors.module.scss';
@use '/src/styles/breakpoints.module.scss';
@use '/src/styles/constants.module.scss';

@mixin section-styles {
  border-bottom: constants.$BORDER-GREY;
  background-color: colors.$interactive-white;
  padding: 1rem 2rem;
}

$desktop-side-margin: 3.5rem;

.header {
  @include section-styles;

  @include breakpoints.media-query-for('small-desktop') {
    padding: 3.5rem $desktop-side-margin 2rem;
  }

  &__nav {
    @include section-styles;

    @include breakpoints.media-query-for('small-desktop') {
      padding: 1rem $desktop-side-margin;
    }
  }
}
