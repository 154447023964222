@use 'styles/constants.module.scss' as constants;
@use 'styles/utils.module.scss' as utils;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/mixins.module.scss' as mixins;
@use 'styles/colors.module.scss' as colors;

@mixin bidding-table-header-modifiers {
  &--bid {
    display: none;
  }
}

@mixin bidding-table-cell-modifiers {
  &--bid {
    padding: 0;
    height: auto;
    width: 100%;
  }
}

.bidding-table {
  width: 100%;
}

.empty-text {
  &__container {
    width: 100%;
    padding: 0.5rem 0;

    @include mixins.flex-container(
      $align-items: center,
      $justify-content: space-between
    );
  }
}

.row {
  padding: 0.5rem 1rem;
  width: 100%;

  @include mixins.flex-container(
    $justify-content: space-between,
    $align-items: center
  );

  &--owner {
    @extend .row;
    background-color: colors.$background-tint-grey;

    &:hover {
      & .delete {
        &__container {
          width: 1.5rem;

          @include mixins.flex-container(
            $align-items: center,
            $justify-content: center
          );
        }
      }
    }
  }

  &__bidder {
    @include mixins.flex-container(
      $direction: column,
      $align-items: flex-start
    );
  }

  &__time {
    color: colors.$text-medium-grey;
  }

  &__bid {
    @include mixins.flex-container($align-items: center);
  }
}

.delete {
  width: utils.pxToRem(13);
  height: utils.pxToRem(14);
  color: colors.$interactive-kg-blue;
  cursor: pointer;

  &__container {
    transition: width 0.2s ease-out;
    overflow: hidden;
    width: 0;
    height: 1.5rem;
    @include mixins.flex-container(
      $align-items: center,
      $justify-content: center
    );
  }
}
