@use 'styles/colors.module.scss' as colors;
@use 'styles/breakpoints.module.scss' as *;
@use 'styles/mixins.module.scss' as mixins;
@use 'styles/z-index.module.scss' as *;
@use 'styles/utils.module.scss' as utils;
@use 'styles/animations.module.scss' as keyframes;

.modal {
  @include mixins.flex-container(
    column,
    space-between,
    flex-start,
    $flex-shrink: 0
  );

  text-align: left;
  border-radius: 0.125rem;
  background: colors.$interactive-white;
  color: colors.$text-black;
  width: 100%;
  height: utils.pxToRem(765);
  position: relative;
  overflow: hidden;
  overflow-x: hidden;
  animation: 0.2s ease-out 0s slideInFromBottomFade;

  &--closing {
    animation: 0.2s ease-out 0s slideOutFromBottomFade forwards;
  }

  @include media-query-for('small-desktop') {
    width: 43rem;
    height: 44.25rem;
  }

  &__overlay {
    padding: 0 1rem;
    z-index: $z-index-form-modal;

    &--closing {
      animation: 0.2s ease-out 0s fadeOut forwards;
    }
  }

  &__container {
    @include mixins.flex-container(
      column,
      $align-items: flex-start,
      $gap: 1rem
    );
    padding: 1.5rem 1.5rem 0;
    align-self: stretch;

    &--wider {
      padding: 1.5rem;

      @include media-query-for('small-desktop') {
        padding: 2rem;
      }
    }
  }

  &__form {
    @include mixins.flex-container(column, $gap: 1rem);
    width: 100%;
    padding: 1rem 1.5rem 0 1.5rem;
    overflow-y: auto;
  }

  &__buttons {
    justify-content: flex-end;
    border-top: utils.pxToRem(1) solid colors.$borders-strokes-border-grey;
    background-color: colors.$interactive-white;
    z-index: $z-index-form-modal-bottom;

    @include media-query-for('small-desktop') {
      flex-direction: row-reverse;
      justify-content: end;
    }
  }

  &__header {
    @include mixins.flex-container(row, space-between, flex-start);
    align-self: stretch;
    padding-bottom: 0.5rem;
    border-bottom: utils.pxToRem(1) solid colors.$borders-strokes-border-grey;
  }

  &__exit-icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    color: colors.$interactive-kg-blue;
    font-size: 1.5rem;

    &:hover {
      cursor: pointer;
    }
  }
}
