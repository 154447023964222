@use 'styles/colors.module.scss';
@use 'styles/typekit.module.scss';
@use 'styles/breakpoints.module.scss';
@use 'styles/utils.module.scss';
@use 'styles/mixins.module.scss';

.swiper-wrapper {
  align-items: center;
  overflow: visible;
}

.swiper-container {
  overflow: visible;
}

.swiper-pagination-fraction {
  @include typekit.d3();
  color: colors.$text-white;
  position: absolute;
  bottom: initial;
  text-align: center;
  top: utils.pxToRem(-58);

  @include breakpoints.media-query-for('small-desktop') {
    top: utils.pxToRem(-103);
  }
}

.preview-slide {
  &.swiper-slide {
    border: 0.125rem solid rgba(colors.$interactive-white, 0);
  }

  &.swiper-slide-active {
    border: 0.125rem solid colors.$interactive-white;
  }
}

.pagination-container {
  position: absolute;
}

@mixin swiper-after {
  width: 2.25rem;
  height: utils.pxToRem(35);
  padding: utils.pxToRem(8) utils.pxToRem(8);
  content: '';
}
