@use '/src/styles/mixins.module.scss';
@use '/src/styles/colors.module.scss';
@use '/src/styles/constants.module.scss';
@use '/src/styles/z-index.module.scss';
@use '/src/styles/utils.module.scss';
@use '/src/styles/breakpoints.module.scss';

.campaign-details {
  @include mixins.flex-container($direction: column);

  &__banner-border {
    @include mixins.flex-container($justify-content: center);
    border-bottom: constants.$BORDER-GREY;
  }

  &__banner-container {
    position: relative;
    width: 100%;
    align-self: center;

    @include breakpoints.media-query-for(constants.$LARGE_DESKTOP_BREAKPOINT) {
      width: 83rem;
    }
  }

  &__tabs-container {
    @include mixins.flex-container($direction: column);
    position: relative;
    margin-bottom: 2rem;
  }

  &__filter-bar {
    margin-top: 1rem;

    &__searchbox-container {
      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        width: utils.pxToRem(250);
      }
    }

    &__searchbox {
      background-color: transparent;

      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        height: utils.pxToRem(45);
      }
    }
  }

  &__actions {
    @include mixins.flex-container($align-items: center, $gap: 1rem);
    position: absolute;
    right: 0;
    top: 1rem;

    &--mobile {
      margin-top: 0.5rem;
      background-color: colors.$background-tint-grey;
    }
  }

  &__actions-menu-container {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: z-index.$z-index-floating-button;

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      top: 2.5rem;
      right: 3rem;
    }

    @include breakpoints.media-query-for(constants.$MEDIUM_DESKTOP_BREAKPOINT) {
      right: 4.5rem;
    }

    @include breakpoints.media-query-for(constants.$LARGE_DESKTOP_BREAKPOINT) {
      right: 2.5rem;
    }
  }

  &__actions-menu {
    @include mixins.flex-container(column, center, center);
    width: 2.5rem;
    height: 2.5rem;
    padding: utils.pxToRem(11.5) 1rem;
    background-color: colors.$background-white;
    border: constants.$BORDER-BLACK;
    border-radius: 0.125rem;

    &:hover {
      background-color: colors.$background-tint-grey;
      outline: utils.pxToRem(6) solid colors.$background-tint-grey;
    }
  }

  &__button-container {
    @include mixins.button-with-icon--icon-hover(colors.$interactive-white);
  }

  &__button {
    @include mixins.flex-container($align-items: center, $gap: 0.6rem);
  }

  &__button-icon {
    @include mixins.flex-container($justify-content: center);
    width: fit-content;
    height: fit-content;

    svg {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &__filters {
    width: utils.pxToRem(169);
  }

  &__message {
    color: colors.$interactive-black;

    &--highlight {
      color: colors.$interactive-kg-blue;
      font-weight: 700;
    }
  }
}
