@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/utils.module.scss' as utils;
@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/breakpoints.module.scss' as breakpoints;

.message-container {
  @include mixins.flex-container(column, center, center, $gap: 1rem);
  background-color: transparent;

  &__title {
    width: 100%;
    color: colors.$text-black;

    @include breakpoints.media-query-for('small-desktop') {
      max-width: 27.8rem;
    }
  }

  &__description {
    width: 100%;
    color: colors.$text-black;

    @include breakpoints.media-query-for('small-desktop') {
      max-width: 29.5rem;
    }

    &--small {
      width: 100%;

      @include breakpoints.media-query-for('small-desktop') {
        max-width: utils.pxToRem(401);
      }
    }
  }

  &__icon {
    width: 5rem;
    height: 5rem;
    margin: 0;
    padding: 0;
    align-self: center;

    &--small {
      width: 4rem;
      height: 4rem;
    }
  }
}

.error-page {
  @include mixins.flex-container(column, center, center, $gap: 1.5rem);
  max-width: 19rem;
  text-align: center;

  @include breakpoints.media-query-for('small-desktop') {
    max-width: 100%;
  }
}
