@use 'styles/colors.module.scss' as colors;
@use 'styles/breakpoints.module.scss' as *;
@use 'styles/mixins.module.scss' as mixins;
@use 'styles/z-index.module.scss' as *;
@use 'styles/utils.module.scss' as utils;

.drawer {
  @include mixins.flex-container(
    column,
    space-between,
    flex-start,
    $flex-shrink: 0
  );
  text-align: left;
  border-radius: 0.125rem;
  background: colors.$interactive-white;
  color: colors.$text-black;
  width: 100%;
  height: 100%;
  position: relative;

  &__overlay {
    z-index: $z-index-drawer;
  }

  &__container {
    @include mixins.flex-container(
      column,
      $align-items: flex-start,
      $gap: 1rem
    );
    padding: 1.5rem 1.5rem 0;
    align-self: stretch;

    &--wider {
      padding: 2rem;
    }
  }

  &__form {
    @include mixins.flex-container(column, $gap: 1rem);
    width: 100%;
    overflow-y: auto;
    padding: 1rem 1.5rem 0 1.5rem;
  }

  &__buttons {
    flex-direction: row-reverse;
    justify-content: end;
    border-top: utils.pxToRem(1) solid colors.$borders-strokes-border-grey;
  }

  &__header {
    @include mixins.flex-container(row, space-between, flex-start);
    align-self: stretch;
    padding-bottom: 0.5rem;
    border-bottom: utils.pxToRem(1) solid colors.$borders-strokes-border-grey;
  }

  &__exit-icon {
    color: colors.$interactive-kg-blue;
    font-size: 1.5rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.auto-width {
  width: auto;
}
