@import './breakpoints.module.scss';

$default-font: 'Helvetica Neue', Arial, sans-serif;
$helvetica-font: 'Helvetica Neue';
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;

@mixin d1 {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: 2.625rem;

  @include media-query-for('small-desktop') {
    font-size: 2.25rem;
    line-height: 2.875rem;
  }
}

@mixin d2 {
  font-size: 1.375rem;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: 1.875rem;

  @include media-query-for('small-desktop') {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

@mixin d3 {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: 1.75rem;

  @include media-query-for('small-desktop') {
    line-height: 1.875rem;
  }
}

@mixin d4 {
  font-size: 1rem;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: 1.625rem;

  @include media-query-for('small-desktop') {
    line-height: 1.75rem;
  }
}

@mixin d5 {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: 1.5rem;
}

@mixin d6 {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: 1.25rem;

  @include media-query-for('small-desktop') {
    line-height: 1.375rem;
  }
}

@mixin p1 {
  font-size: 1rem;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: 1.625rem;

  @include media-query-for('small-desktop') {
    line-height: 2rem;
  }
}

@mixin p2 {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: 1.5rem;

  @include media-query-for('small-desktop') {
    line-height: 1.375rem;
  }
}

@mixin p3 {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: 1.25rem;

  @include media-query-for('small-desktop') {
    line-height: 1.125rem;
  }
}

@mixin p4 {
  font-size: 0.6rem;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: 1.25rem;

  @include media-query-for('small-desktop') {
    line-height: 1.125rem;
  }
}

@mixin s2 {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: 1.25rem;
}
