@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/breakpoints.module.scss' as breakpoints;
@use 'src/styles/utils.module.scss' as utils;
@use 'src/styles/constants.module.scss' as constants;

$campaign-card-small-desktop-width: 841;
$campaign-card-small-desktop-height: 251;
$campaign-card-medium-desktop-width: 578;
$campaign-card-large-desktop-width: 656;

$auction-item-mobile-width: 396;
$auction-item-small-desktop-width: 412.5;
$auction-item-medium-desktop-width: 280;
$auction-item-large-desktop-width: 320;

$auction-item-details-medium-desktop-width: 440;
$auction-item-details-small-desktop-width: 404.5;

$notification-card-desktop-width: 390;

.card {
  background-color: colors.$background-white;
  border: constants.$BORDER-GREY;
  transition: background-color 0.2s ease-out;

  &--pressed {
    &:active {
      background-color: colors.$interactive-disabled-grey;
    }
  }

  &__link {
    text-decoration: none;
    color: inherit;

    @include breakpoints.media-query-for('small-desktop') {
      display: inline-block;
    }
  }

  &-campaign {
    @include breakpoints.media-query-for('small-desktop') {
      width: 100%;
      height: utils.pxToRem($campaign-card-small-desktop-height);
    }
  }

  &-auction-item {
    width: utils.pxToRem($auction-item-mobile-width);
    height: fit-content;

    @include breakpoints.media-query-for('small-desktop') {
      width: utils.pxToRem($auction-item-small-desktop-width);
    }

    @include breakpoints.media-query-for('medium-desktop') {
      width: utils.pxToRem($auction-item-medium-desktop-width);
    }

    @include breakpoints.media-query-for('large-desktop') {
      width: utils.pxToRem($auction-item-large-desktop-width);
    }

    &-details {
      @include breakpoints.media-query-for('small-desktop') {
        width: utils.pxToRem($auction-item-details-small-desktop-width);
      }

      @include breakpoints.media-query-for('medium-desktop') {
        width: utils.pxToRem($auction-item-details-medium-desktop-width);
      }
    }

    &-notification {
      @include breakpoints.media-query-for('small-desktop') {
        width: utils.pxToRem($notification-card-desktop-width);
      }
    }
  }
}
