@use 'styles/colors.module.scss';
@use 'styles/utils.module.scss';
@use 'styles/animations.module.scss';

.chevron {
  font-size: 2rem;

  &--small {
    font-size: 1rem;
  }
}

.loading {
  opacity: 0;
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}

.icon {
  color: inherit;

  &--small {
    width: utils.pxToRem(14);
    height: utils.pxToRem(14);
  }

  &--large {
    width: 1rem;
    height: 1rem;
  }

  &--link {
    width: utils.pxToRem(18);
    height: utils.pxToRem(18);
  }

  &__spinner {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    animation: fadeIn 0.2s ease-in-out 0.1s both;
  }
}
