@use 'styles/constants.module.scss' as constants;
@use 'styles/utils.module.scss' as utils;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/mixins.module.scss' as mixins;

@mixin column-size($size) {
  min-width: utils.pxToRem($size);
  width: utils.pxToRem($size);
}

@mixin user-table-cell-modifiers {
  &--role {
    @include column-size(166);

    @include breakpoints.media-query-for(
      constants.$USER_TABLE_SPECIAL_BREAKPOINT
    ) {
      width: calc((100% - utils.pxToRem(491)) / 2);
    }
  }

  &--email {
    @include column-size(238);

    @include breakpoints.media-query-for(constants.$MEDIUM_DESKTOP_BREAKPOINT) {
      @include column-size(253);
    }
  }

  &--name {
    @include column-size(156);

    @include breakpoints.media-query-for(constants.$MEDIUM_DESKTOP_BREAKPOINT) {
      @include column-size(149);
    }
  }

  &--office {
    @include column-size(166);

    @include breakpoints.media-query-for(
      constants.$USER_TABLE_SPECIAL_BREAKPOINT
    ) {
      width: calc((100% - utils.pxToRem(491)) / 2);
    }
  }

  &--image {
    @include column-size(89);
  }
}

@mixin user-table-cell-input-modifiers {
  &--role {
    width: 100%;
  }
}

.profile-picture {
  border-radius: 50%;

  &__container {
    width: utils.pxToRem(57);
  }
}

.table {
  &__container {
    @include mixins.table-scroll-bar-styles(
      constants.$USER_TABLE_SPECIAL_BREAKPOINT
    );
  }
}
