@use 'styles/colors.module.scss' as *;

$color-mappings: (
  interactive-black: $interactive-black,
  interactive-white: $interactive-white,
  interactive-disabled-grey: $interactive-disabled-grey,
  text-disabled-text: $text-disabled-text,
  interactive-kg-blue: $interactive-kg-blue,
  text-medium-grey: $text-medium-grey,
  interactive-light-grey: $interactive-light-grey,
  interactive-soft-blue: $interactive-soft-blue,
  system-error-red: $system-error-red,
  system-error-tint-red: $system-error-tint-red,
  system-success-green: $system-success-green,
  system-success-tint-green: $system-success-tint-green,
  system-caution-yellow: $system-caution-yellow,
  system-caution-tint-yellow: $system-caution-tint-yellow,
);

$variant-mappins: (
  minor: (
    size: 1rem,
    flex-shrink: 0,
  ),
  major: (
    size: 1.5rem,
    flex-shrink: 0,
  ),
  spot: (
    size: 5rem,
  ),
);

.icon {
  background-color: transparent;
  padding: 0;
  border: 0;
  color: inherit;

  @each $variant, $attributes in $variant-mappins {
    $size: map-get($attributes, size);
    $flex-shrink: map-get($attributes, flex-shrink);

    &--variant-#{$variant}-font-awesome {
      font-size: $size;
      flex-shrink: $flex-shrink;
    }

    &--variant-#{$variant}-svg {
      height: $size;
      width: $size;
      flex-shrink: $flex-shrink;
    }
  }

  @each $class, $color in $color-mappings {
    &--#{$class} {
      color: $color;
    }
  }
}
