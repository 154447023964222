@use 'src/styles/breakpoints.module.scss' as breakpoints;
@use 'src/styles/typekit.module.scss' as typekit;
@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/utils.module.scss' as utils;

.card {
  @include mixins.flex-container(row);
  overflow: hidden;
  position: relative;

  @include breakpoints.media-query-for('small-desktop') {
    flex-direction: column;

    &:hover &__carousel-container :global(.image-slider-img) {
      transform: scale(1.3);
    }

    &:hover &__carousel-container :global(.swiper-button-prev),
    &:hover &__carousel-container :global(.swiper-button-next) {
      opacity: 1;
    }

    &:hover &__carousel-container :global(.swiper-button-disabled) {
      opacity: 0;
    }
  }

  &__secondary-info {
    display: flex;
    flex-direction: column;
    gap: utils.pxToRem(12);
  }

  &__img {
    object-fit: cover;
    object-position: center;
    transition: transform 0.5s;
    height: 100%;
    width: 100%;
  }

  &__img-cover {
    width: 100%;
    height: 100%;
  }

  &__link {
    text-decoration: none;
    color: colors.$text-black;
    width: 100%;

    &:active {
      background-color: colors.$interactive-disabled-grey;
    }
  }

  &__img-container {
    overflow: hidden;
    position: absolute;
    height: 100%;
    flex-shrink: 0;
    width: utils.pxToRem(109);

    @include breakpoints.media-query-for('small-desktop') {
      width: 100%;
      height: utils.pxToRem(258);
      position: relative;
      left: 0;

      &--completed {
        height: utils.pxToRem(285);
      }
    }
  }

  &__carousel-container {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    max-width: utils.pxToRem(411);

    :global(.swiper-button-disabled) {
      opacity: 0;
    }

    &--completed {
      :global(.swiper-button-next),
      :global(.swiper-button-prev) {
        top: 11rem;
      }
    }
  }

  &__content {
    @include mixins.flex-container(row, space-between, $gap: 1.5rem);
    padding: 1rem;
    position: relative;
    left: utils.pxToRem(109);
    width: calc(100% - utils.pxToRem(109));

    @include breakpoints.media-query-for('small-desktop') {
      width: 100%;
      height: utils.pxToRem(222);
      left: 0;

      &--completed {
        height: 12rem;
      }
    }

    &--column {
      flex-direction: column;
      padding: 0;
      gap: 0;
    }
  }

  &__category {
    border-radius: utils.pxToRem(2);
    border: utils.pxToRem(1) solid colors.$text-disabled-grey;
    padding: 0.1rem 0.25rem;
    width: fit-content;
  }

  &__info {
    @include mixins.flex-container(column, $gap: 0.25rem);
    color: colors.$text-black;

    &--text {
      color: colors.$text-medium-grey;
    }
  }

  &__title {
    width: utils.pxToRem(248);
    display: inline-block;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include breakpoints.media-query-for('small-desktop') {
      -webkit-line-clamp: 2;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &--spacing {
      @include typekit.d4;
      padding: 1rem;
    }
  }

  &__price-container {
    position: static;
    display: flex;

    @include breakpoints.media-query-for('small-desktop') {
      position: absolute;
      top: utils.pxToRem(-40);
      right: 0;
      padding: 1rem;
    }
  }

  &__price {
    display: flex;
    flex-wrap: wrap;
    border-radius: utils.pxToRem(2);
    padding: 0.75rem;
    justify-content: center;
    background-color: colors.$interactive-black;
    min-width: utils.pxToRem(50.88);
    height: fit-content;
  }

  &__price-amount {
    color: colors.$interactive-white;
  }

  &__trend-circle {
    @include mixins.flex-container(row, center, center);
    position: absolute;
    float: left;
    width: 2rem;
    height: 2rem;
    padding: utils.pxToRem(7);
    margin: 0.5rem;
    border-radius: utils.pxToRem(100);
    outline: utils.pxToRem(1) solid colors.$interactive-kg-blue;
    background: colors.$interactive-white;

    @include breakpoints.media-query-for('small-desktop') {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.5rem;
    }
  }

  &__trend-arrow {
    width: 1.5rem;
    height: 1.5rem;
    justify-self: center;
  }

  &__winner-banner {
    width: 100%;

    @include breakpoints.media-query-for('small-desktop') {
      position: absolute;
      top: utils.pxToRem(-290);
      left: 0;
    }
  }

  &__donor-banner {
    position: absolute;
    bottom: utils.pxToRem(32);
    width: 100%;

    @include breakpoints.media-query-for('small-desktop') {
      bottom: utils.pxToRem(42);
    }
  }

  &__locations {
    position: absolute;
    bottom: utils.pxToRem(12);
    padding: 0 utils.pxToRem(16);
    width: 100%;
  }
}
