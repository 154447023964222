@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/breakpoints.module.scss' as breakpoint;

.tab {
  background-color: colors.$background-tint-grey;
  transition: background-color 0.3s ease-in-out;

  &-item {
    display: inline-block;
    padding: 1rem 1rem 1.25rem 1rem;
    text-decoration: none;
    color: colors.$interactive-black;
    position: relative;

    &:hover {
      text-decoration: underline;
    }

    @include breakpoint.media-query-for('medium-desktop') {
      padding: 1rem 1.5rem;
    }
  }

  &-list {
    overflow-x: auto;
    padding: 2px 2px 0 2px; // Avoid the overflow to overlap the focus styles
    border-bottom: 1px solid colors.$borders-strokes-border-grey;
    white-space: nowrap;
    position: relative;

    -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
    scrollbar-width: none; /* Hide scrollbar Firefox */

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }

    &__indicator {
      position: absolute;
      bottom: 0;
      height: 0.25rem;
      transition:
        width 0.3s ease-in-out,
        left 0.3s ease-in-out;
      background-color: colors.$interactive-black;
    }
  }
}
