@use 'styles/colors.module.scss';
@use 'styles/breakpoints.module.scss';
@use 'styles/utils.module.scss';
@use 'styles/mixins.module.scss';

@mixin button-styling($border-color, $background-color, $font-color) {
  border: utils.pxToRem(1) solid $border-color;
  background: $background-color;
  color: $font-color;
}

.button {
  position: relative;
  font-family: inherit;
  font-weight: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: utils.pxToRem(10);
  text-align: center;
  border-radius: utils.pxToRem(2);
  align-self: stretch;
  width: 100%;
  cursor: pointer;
  transition:
    color 0.2s ease-in-out 0s,
    background 0.2s ease-in-out 0s,
    border 0.2s ease-in-out 0s,
    box-shadow 0.2s ease-in-out;

  @include breakpoints.media-query-for('small-desktop') {
    width: auto;
    align-self: auto;
  }

  &--large {
    padding: utils.pxToRem(12) utils.pxToRem(30);
  }

  &--small {
    padding: 0.5rem 1.5rem;

    height: utils.pxToRem(40);
  }

  &--primary {
    @extend .button;
    @include button-styling(
      transparent,
      colors.$interactive-kg-blue,
      colors.$interactive-white
    );

    &:focus {
      @include button-styling(
        colors.$text-black,
        colors.$interactive-white,
        colors.$text-black
      );
      box-shadow: 0 0 0 utils.pxToRem(6) colors.$interactive-light-grey;
      outline: none;
    }

    &:hover {
      @include button-styling(
        colors.$text-black,
        colors.$interactive-white,
        colors.$text-black
      );
    }

    &:active {
      @include button-styling(
        colors.$text-black,
        colors.$interactive-light-grey,
        colors.$text-black
      );
    }

    &:disabled {
      @include button-styling(
        transparent,
        colors.$interactive-disabled-grey,
        colors.$text-disabled-text
      );
    }
  }

  &--secondary {
    @extend .button;
    @include button-styling(
      colors.$interactive-kg-blue,
      colors.$interactive-white,
      colors.$interactive-kg-blue
    );

    &:focus {
      @include button-styling(
        transparent,
        colors.$interactive-kg-blue,
        colors.$interactive-white
      );

      outline: none;
      box-shadow: 0 0 0 utils.pxToRem(6) colors.$interactive-light-grey;
    }

    &:hover {
      @include button-styling(
        transparent,
        colors.$interactive-kg-blue,
        colors.$interactive-white
      );
    }

    &:active {
      @include button-styling(
        transparent,
        colors.$interactive-light-grey,
        colors.$text-black
      );
    }
    &:disabled {
      @include button-styling(
        colors.$text-disabled-text,
        colors.$interactive-disabled-grey,
        colors.$text-disabled-text
      );
    }
  }
}

.link {
  font-family: inherit;
  font-weight: inherit;
  color: colors.$text-black;
  border-radius: utils.pxToRem(2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: utils.pxToRem(5);
  text-decoration: none;
  transition:
    background 0.2s ease-in-out 0s,
    box-shadow 0.2s ease-in-out;

  &:hover {
    text-decoration-line: underline;
  }

  &:active {
    background: colors.$interactive-light-grey;
  }

  &:focus {
    box-shadow: 0 0 0 utils.pxToRem(6) colors.$interactive-light-grey;
    outline: none;
  }
  &--disabled {
    @extend .link;
    color: colors.$text-disabled-text;
    pointer-events: none;
    cursor: default;
  }
}
