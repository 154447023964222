@use '/src/styles/colors.module.scss';
@use '/src/styles/typekit.module.scss';
@use '/src/styles/breakpoints.module.scss';
@use '/src/styles/mixins.module.scss';

.breadcrumbs {
  @include mixins.flex-container($align-items: center, $gap: 0.625rem);

  &__link {
    padding: 0;
    text-transform: capitalize;

    &:focus {
      box-shadow: none;
      outline: 0.1875rem solid colors.$interactive-light-grey;
    }

    &:active {
      outline: none;
      border-radius: 0;
      text-decoration: none;

      @include breakpoints.media-query-for('small-desktop') {
        text-decoration: underline;
      }
    }

    &:last-child {
      color: colors.$text-medium-grey;
    }

    span {
      @include typekit.p2;
    }
  }

  &__last-separator {
    color: colors.$text-medium-grey;
  }
}
