@use '/src/styles/typekit.module.scss' as typekit;
@use '/src/styles/utils.module.scss' as utils;

:global(.sr-only) {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@font-face {
  font-family: typekit.$helvetica-font;
  src: url('./styles/fonts/HelveticaNeue-Regular.woff2') format('woff2');
  font-weight: typekit.$font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: typekit.$helvetica-font;
  src: url('./styles/fonts/HelveticaNeue-Medium.woff2') format('woff2');
  font-weight: typekit.$font-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: typekit.$helvetica-font;
  src: url('./styles/fonts/HelveticaNeue-Bold.woff2') format('woff2');
  font-weight: typekit.$font-weight-bold;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: typekit.$default-font;
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
label,
br,
sup,
sub,
u,
i,
b,
em,
strong,
del,
ins,
ruby,
kbd,
wbr {
  margin: 0;
  padding: 0;
}
