@use 'src/styles/breakpoints.module.scss' as breakpoints;
@use 'src/styles/mixins.module.scss' as mixins;

.toasts-container {
  @include mixins.flex-container(column, $align-items: center);
  max-height: 50%;
  width: fit-content;
  padding: 0 2rem;
  position: fixed;
  bottom: 1.5rem;

  @include breakpoints.media-query-for('small-tablet') {
    align-items: flex-start;
    padding: 0;
    overflow: scroll;
    position: fixed;
    bottom: 2rem;
    left: 1rem;
  }
}
