@use 'styles/utils.module.scss' as utils;
@use 'styles/colors.module.scss' as colors;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/constants.module.scss' as constants;
@use 'styles/z-index.module.scss' as z-index;
@use 'styles/mixins.module.scss' as mixins;

.hero {
  $hero-mobile-height: utils.pxToRem(213);
  $hero-large-mobile-height: utils.pxToRem(272);
  $hero-desktop-height: utils.pxToRem(350);
  $hero-medium-desktop-height: utils.pxToRem(287);
  $hero-info-gap-mobile: utils.pxToRem(4);
  $hero-info-gap-desktop: utils.pxToRem(16);
  $hero-actions-gap-desktop: utils.pxToRem(32);
  $hero-actions-gap-mobile: utils.pxToRem(16);
  $hero-actions-padding: utils.pxToRem(1.702);
  $hero-actions-settings-width: utils.pxToRem(32);
  $hero-actions-settings-height: utils.pxToRem(32);
  $hero-actions-logout-width: utils.pxToRem(28);
  $hero-actions-logout-height: utils.pxToRem(28);
  $hero-image-background-width: 100%;
  $hero-overlay-background-color: rgba(0, 0, 0, 0.2);

  height: $hero-mobile-height;
  overflow: visible;
  position: relative;
  z-index: z-index.$z-index-hero;

  @include breakpoints.media-query-for(constants.$LARGE_MOBILE_BREAKPOINT) {
    min-height: $hero-large-mobile-height;
  }

  @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
    min-height: $hero-desktop-height;
  }

  @include breakpoints.media-query-for(constants.$MEDIUM_DESKTOP_BREAKPOINT) {
    min-height: $hero-medium-desktop-height;
  }

  @include breakpoints.media-query-for(constants.$LARGE_DESKTOP_BREAKPOINT) {
    min-height: $hero-desktop-height;
  }

  &__background {
    position: absolute;
    inset: 0;
    object-fit: cover;
    width: $hero-image-background-width;
    height: $hero-mobile-height;

    @include breakpoints.media-query-for(constants.$LARGE_MOBILE_BREAKPOINT) {
      min-height: $hero-large-mobile-height;
    }

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      min-height: $hero-desktop-height;
    }

    @include breakpoints.media-query-for(constants.$MEDIUM_DESKTOP_BREAKPOINT) {
      min-height: $hero-medium-desktop-height;
    }

    @include breakpoints.media-query-for(constants.$LARGE_DESKTOP_BREAKPOINT) {
      min-height: $hero-desktop-height;
    }
  }

  &__overlay {
    position: absolute;
    inset: 0;
    background: $hero-overlay-background-color;
    z-index: z-index.$z-index-hero-overlay;
  }

  &__content {
    @include mixins.flex-container(column, space-between);
    height: 100%;
    position: relative;
    padding: 0.37rem 0.37rem 1rem 1rem;

    @include breakpoints.media-query-for(constants.$LARGE_MOBILE_BREAKPOINT) {
      padding-bottom: 4.69rem;
    }

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      padding: 2.37rem 3.5rem 3.5rem 3.5rem;
    }

    @include breakpoints.media-query-for(constants.$LARGE_DESKTOP_BREAKPOINT) {
      max-width: 108rem;
      margin: 0 auto;
    }
  }

  &__kg-icon {
    color: colors.$interactive-white;
  }

  &__info {
    &-container {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: z-index.$z-index-hero-info;
      gap: $hero-info-gap-mobile;
      color: colors.$text-white;

      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        gap: $hero-info-gap-desktop;
      }
    }

    &-text {
      margin: 0;
      padding: 0;
    }
  }

  &__actions-container {
    @include mixins.flex-container($justify-content: flex-end, $flex-shrink: 0);
    z-index: z-index.$z-index-hero-actions;
    padding: $hero-actions-padding;
    gap: $hero-actions-gap-mobile;

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      gap: $hero-actions-gap-desktop;
    }
  }

  &__actions {
    @include mixins.flex-container(row, $gap: 1.5rem);
    padding: 0.62rem;

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      padding: 0;
    }

    &--settings {
      width: $hero-actions-settings-width;
      height: $hero-actions-settings-height;
      color: colors.$interactive-white;

      &:hover {
        cursor: pointer;
      }
    }

    &-logout {
      svg {
        width: $hero-actions-logout-width;
        height: $hero-actions-logout-height;
        color: colors.$interactive-white;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
