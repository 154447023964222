@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/constants.module.scss' as constants;
@use 'styles/utils.module.scss' as utils;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scaleUp30PercentToOrigin {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleDown30PercentFromOrigin {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideInFromBottomFade {
  0% {
    transform: translateY(30%);
    opacity: 0%;
  }
  100% {
    transform: translateY(0%);
    opacity: 100%;
  }
}

@keyframes extendCategoryInput {
  0% {
    width: 0;
  }
  100% {
    width: 150px;
  }
}

@keyframes shrinkCategoryInput {
  0% {
    width: 150px;
  }
  100% {
    width: 0;
  }
}

@keyframes slideOutFromBottomFade {
  0% {
    transform: translateY(0%);
    opacity: 100%;
  }
  100% {
    transform: translateY(30%);
    opacity: 0%;
  }
}

@keyframes slideInFromTopFade {
  0% {
    transform: translateY(-30%);
    opacity: 0%;
  }
  100% {
    transform: translateY(0%);
    opacity: 100%;
  }
}

@keyframes slideOutFromTopFade {
  0% {
    transform: translateY(0%);
    opacity: 100%;
  }
  100% {
    transform: translateY(-30%);
    opacity: 0%;
  }
}

@keyframes slideInFromRightFade {
  0% {
    transform: translateX(10%);
    opacity: 0%;
  }
  100% {
    transform: translateX(0%);
    opacity: 100%;
  }
}

@keyframes slideOutFromRightFade {
  0% {
    transform: translateX(0%);
    opacity: 100%;
  }
  100% {
    transform: translateX(10%);
    opacity: 0%;
  }
}

@keyframes slideInFromLeftFade {
  0% {
    transform: translateX(-10%);
    opacity: 0%;
  }
  100% {
    transform: translateX(0%);
    opacity: 100%;
  }
}

@keyframes slideOutFromLeftFade {
  0% {
    transform: translateX(0%);
    opacity: 100%;
  }
  100% {
    transform: translateX(-10%);
    opacity: 0%;
  }
}

@keyframes slideOutFromLeftFade {
  0% {
    transform: translateX(0%);
    opacity: 100%;
  }
  100% {
    transform: translateX(-10%);
    opacity: 0%;
  }
}

@keyframes extendTopToBottom {
  0% {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  100% {
    max-height: utils.pxToRem(270);
  }
}

@keyframes shrinkTopToBottom {
  0% {
    max-height: utils.pxToRem(270);
  }
  100% {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@keyframes extendTopToBottomDatePicker {
  0% {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  100% {
    max-height: utils.pxToRem(343);
  }
}

@keyframes extendLeftToRight {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes shrinkTopToBottomDatePicker {
  0% {
    max-height: utils.pxToRem(343);
  }
  100% {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@keyframes spin180DegreesClockwise {
  100% {
    transform: rotate(180deg);
  }
}

@keyframes spin360DegreesClockwise {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin180DegreesClockwiseToOrigin {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes spin180DegreesCounterClockwise {
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes spin180DegreesCounterClockwiseToOrigin {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes checkbox-check {
  0% {
    width: 0;
  }
  33% {
    width: 0.5rem;
  }
  100% {
    width: 1.1rem;
  }
}

@keyframes checkbox-uncheck {
  0% {
    width: 1.1rem;
  }
  33% {
    width: 0.5rem;
  }
  100% {
    width: 0;
  }
}
