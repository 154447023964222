@use 'src/styles/utils.module.scss' as utils;

.displaytime {
  display: flex;
  flex-direction: row;

  &--spaced {
    margin: 0.5rem;
  }

  &__number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    &--spaced {
      width: 4rem;
    }

    &__type {
      display: flex;
      justify-content: center;
      align-content: center;
    }
  }
}
