@use 'src/styles/breakpoints.module.scss' as breakpoints;
@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/utils.module.scss' as utils;
@use 'src/styles/z-index.module.scss' as z-index;

.notifications-container {
  @include mixins.flex-container(column);
  overflow: auto;
  max-height: 50%;
  width: utils.pxToRem(390);
  margin: auto;
  z-index: z-index.$z-index-notification;

  @include breakpoints.media-query-for('small-desktop') {
    position: fixed;
    right: 2rem;
    top: 2rem;
  }
}
