@use '/src/styles/z-index.module.scss';

.notification-wrapper {
  width: 100%;
  height: 100%;
  z-index: z-index.$z-index-notification-wrapper;
  pointer-events: none;
  position: absolute;
  background-color: transparent;
}
