@use 'styles/mixins.module.scss' as mixins;
@use 'styles/utils.module.scss' as utils;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/colors.module.scss' as colors;

@keyframes slideOpen {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideClose {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes showOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes closeOverlay {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.sheet {
  @include mixins.flex-container(row, center, flex-end);

  &--open {
    animation: showOverlay 0.5s forwards ease-out;
  }

  &--close {
    animation: closeOverlay 0.5s forwards ease-out;
  }

  &__container {
    align-items: flex-start;
    align-self: flex-end;
    height: fit-content;
    width: 100%;
    padding: 1.5rem;
    gap: 1.5rem;
    transform: translateY(100%);
    background-color: colors.$interactive-white;

    &--open {
      animation: slideOpen 0.5s forwards ease-out;
    }

    &--close {
      animation: slideClose 0.5s forwards ease-in;
    }
  }

  &__content {
    @include mixins.flex-container(column, $gap: 1.5rem);
    margin: 1.5rem 0;
  }

  &__title {
    @include mixins.flex-container(row, space-between);
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: solid colors.$borders-strokes-border-grey 0.0625rem;

    &__text {
      color: colors.$text-black;
    }

    &__icon {
      width: 1rem;
      height: 1rem;
      align-self: center;
      margin: 0 0.625rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__button {
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }
}
