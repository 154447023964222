@use '/src/styles/colors.module.scss';
@use '/src/styles/typekit.module.scss';
@use '/src/styles/mixins.module.scss';

@mixin tag-colors($background-color, $color) {
  background-color: $background-color;
  color: $color;
}

.tag {
  @include typekit.d6;
  @include mixins.flex-container(
    $justify-content: center,
    $align-items: center
  );
  background-color: colors.$background-tint-grey;
  color: colors.$text-medium-grey;
  border-radius: 6.25rem;
  padding: 0.125rem 0.25rem;
  width: 5rem;
  text-transform: capitalize;

  &--active {
    @include tag-colors(
      colors.$system-success-tint-green,
      colors.$system-success-green
    );
  }

  &--pending {
    @include tag-colors(
      colors.$system-caution-tint-yellow,
      colors.$system-caution-yellow
    );
  }

  &--completed {
    @include tag-colors(
      colors.$interactive-soft-blue,
      colors.$interactive-kg-blue
    );
  }
}
