@use 'styles/mixins.module.scss' as mixins;
@use 'styles/breakpoints.module.scss';

.loading {
  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 2rem 1rem 0;

    @include mixins.flex-container(
      $direction: column,
      $align-items: center,
      $justify-content: center
    );

    @include breakpoints.media-query-for('small-desktop') {
      padding: 2rem 3.5rem 0;
    }
  }
}
