@import 'src/styles/colors.module.scss';

.showcountdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  &__colon {
    align-self: center;

    &--spaced {
      margin: 0.5rem;
    }
  }
}
