@use 'styles/colors.module.scss' as colors;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/typekit.module.scss' as typography;
@use 'styles/utils.module.scss' as utils;
@use 'styles/constants.module.scss' as constants;
@use 'styles/animations.module.scss' as keyframes;
@use 'styles/z-index.module.scss' as *;

// Mobile Styles
$mobile-week-width: utils.pxToRem(310);
$mobile-datepicker-padding: utils.pxToRem(24) utils.pxToRem(16);
$mobile-datepicker-width: utils.pxToRem(343);
$mobile-datepicker-gap: utils.pxToRem(16);
$mobile-month-height: utils.pxToRem(227);
$mobile-day-width: utils.pxToRem(44);
$mobile-day-padding: utils.pxToRem(8);
$mobile-today-circle-width: utils.pxToRem(31.41);
$mobile-button-padding: utils.pxToRem(10);
$mobile-navigation-margin-top: utils.pxToRem(16);

// Desktop Styles
$desktop-week-width: utils.pxToRem(238);
$desktop-datepicker-padding: utils.pxToRem(16);
$desktop-datepicker-width: 100%;
$desktop-datepicker-gap: utils.pxToRem(4);
$desktop-datepicker-two-calendar-gap: utils.pxToRem(32);
$desktop-datepicker-left-margin: 10%;
$desktop-day-width: utils.pxToRem(35);
$desktop-today-circle-width: utils.pxToRem(29.05);
$desktop-navigation-margin-top: utils.pxToRem(8);

// Shared Styles
$datepicker-box-shadow: 0px 4px 5px 0px #00000008;
$week-day-name-height: utils.pxToRem(34);
$current-month-height: utils.pxToRem(18);
$next-arrow-padding: utils.pxToRem(13) utils.pxToRem(0);
$next-arrow-gap: utils.pxToRem(10);
$header-gap: utils.pxToRem(8);
$week-day-name-padding: utils.pxToRem(8) utils.pxToRem(0);

.required-mark {
  color: colors.$system-error-red;
}

.date-picker {
  display: flex;
  flex-direction: column;
  gap: $desktop-datepicker-gap;
  z-index: $z-index-date-picker;

  &__label {
    align-self: stretch;
    color: colors.$text-medium-grey;
  }

  &--closing {
    & .react-datepicker {
      animation: 0.3s ease-out 0s shrinkTopToBottomDatePicker forwards;
    }
  }
}

.react-datepicker {
  display: flex;
  align-items: flex-start;
  padding: $mobile-datepicker-padding;
  width: $mobile-datepicker-width;
  gap: $mobile-datepicker-gap;
  border: constants.$DEFAULT_BORDER_SIZE solid colors.$interactive-disabled-grey;
  background: colors.$interactive-white;
  box-shadow: $datepicker-box-shadow;
  animation: 0.3s ease-out 0s extendTopToBottomDatePicker;
  overflow: hidden;

  @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
    padding: $desktop-datepicker-padding;
    width: $desktop-datepicker-width;
    gap: $desktop-datepicker-two-calendar-gap;
    margin-left: $desktop-datepicker-left-margin;
  }

  &__input-container {
    background-color: colors.$interactive-white;
  }

  &__navigation {
    margin-top: $mobile-navigation-margin-top;
    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      margin-top: $desktop-navigation-margin-top;
    }

    &-icon {
      border: none;
      &::before {
        border-color: colors.$interactive-black !important; // Adjusts the library behavior by preventing the color from changing to grey when the user hovers over navigation controls (arrows).
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    background: none;
    border-bottom: none;
    padding: 0;
    gap: $header-gap;
  }

  &__month {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    margin: 0;
    padding: 0;
    border-radius: utils.pxToRem(2);
    background: colors.$interactive-white;
    height: $mobile-month-height;

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      height: auto;
    }

    &-container {
      align-self: stretch;
      width: 100%;
      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        width: 100%;
      }
    }
  }

  &__current-month {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: $current-month-height;
    color: colors.$interactive-black;
    @include typography.d5;
  }

  &__day {
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    flex: 1 0 0;
    border-radius: 0;
    margin: 0;
    padding: $mobile-day-padding;
    border: constants.$DEFAULT_BORDER_SIZE solid
      colors.$interactive-disabled-grey;
    width: $mobile-day-width;
    color: colors.$interactive-black;
    transition:
      background-color 0.4s ease-in-out,
      color 0.4s ease-in-out,
      outline 0.4s ease-in-out,
      height 0.4s ease-in-out;

    @include typography.p2;
    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      width: $desktop-day-width;
    }
    &:not(:first-child) {
      border-left: none;
    }

    &--today {
      position: relative;
      background-color: colors.$interactive-white;
      &:not(.react-datepicker__day--outside-month) {
        &::after {
          content: '';
          position: absolute;
          border-radius: 100%;
          border: constants.$DEFAULT_BORDER_SIZE solid colors.$interactive-black;
          width: $mobile-today-circle-width;
          height: $mobile-today-circle-width;
          @include typography.p2;
          @include breakpoints.media-query-for(
            constants.$SMALL_DESKTOP_BREAKPOINT
          ) {
            width: $desktop-today-circle-width;
            height: $desktop-today-circle-width;
          }
        }
      }
    }

    &-names {
      display: flex;
      align-items: flex-start;
      width: $mobile-week-width;
      @include typography.p3;
      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        width: $desktop-week-width;
      }
    }

    &-name {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      padding: 0.5rem 0rem;
      text-align: center;
      height: $week-day-name-height;
      color: colors.$text-medium-grey;
    }

    &--in-range {
      border-radius: none;
      background: colors.$interactive-black;
      color: colors.$interactive-white;
    }

    &--in-selecting-range {
      &:not(
          .react-datepicker__day--in-range,
          .react-datepicker__month-text--in-range,
          .react-datepicker__quarter-text--in-range,
          .react-datepicker__year-text--in-range,
          .react-datepicker__day--outside-month
        ) {
        background-color: colors.$interactive-light-grey;
      }
    }

    &--outside-month {
      background-color: colors.$interactive-white !important; // Modifies the default library behavior to prevent color changes to blue when selecting a range.
    }

    &--selecting-range {
      &-start {
        background: colors.$interactive-light-grey;
      }
    }

    &--selected {
      background: colors.$interactive-black;
      color: colors.$interactive-white;
      border-radius: 0;
    }

    &:hover {
      background: colors.$interactive-light-grey;
      color: colors.$interactive-black;
      border-radius: 0;

      &::after {
        content: none;
      }
    }
  }

  &__week {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    width: 100%;

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      width: 100%;
    }

    background: colors.$interactive-white;
    &:not(:last-child) {
      .react-datepicker__day {
        border-bottom: none;
      }
    }
  }
}
