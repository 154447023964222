@use 'styles/utils.module.scss' as utils;
@use 'styles/colors.module.scss' as colors;
@use 'styles/mixins.module.scss' as mixins;
@use 'styles/typekit.module.scss' as typekit;
@use 'styles/animations.module.scss' as keyframes;

.checkbox-container {
  @include mixins.flex-container($align-items: center, $gap: 0.5rem);
  padding: 0.25rem 0.25rem 0.25rem 0rem;
  transition:
    color 0.3s ease-in-out 0s,
    background-color 0.3s ease-in-out 0s,
    border 0.3s ease-in-out 0s,
    box-shadow 0.3s ease-in-out,
    border-radius 0.3s ease-in-out;

  &:focus-within {
    padding-left: 0.25rem;
    margin-left: -0.25rem;
    box-shadow: 0 0 0 0.375rem colors.$interactive-light-grey;
    border-radius: 0.25rem;
  }

  &:hover:not(:has(.hidden-checkbox:disabled)) {
    cursor: pointer;

    .checkbox {
      cursor: pointer;
      background-color: colors.$interactive-light-grey;
    }

    .checkbox-label {
      text-decoration: underline;
    }
  }
}

.hidden-checkbox {
  appearance: none;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.checkbox {
  flex-shrink: 0;
  appearance: none;
  width: 1.125rem;
  height: 1.125rem;
  margin: 0;
  position: relative;
  border: utils.pxToRem(2) solid colors.$text-medium-grey;
  border-radius: 10%;
  background-color: colors.$interactive-white;
  outline: none;
  transition:
    color 0.3s ease-in-out 0s,
    background-color 0.3s ease-in-out 0s,
    border 0.3s ease-in-out 0s,
    box-shadow 0.3s ease-in-out,
    border-radius 0.3s ease-in-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 1.1rem;
    height: 1.1rem;
    bottom: 0;
    left: 0;
    transform: translate(-10%, 10%);
    background-color: transparent;
    pointer-events: none;
    overflow: hidden;
  }
}

.hidden-checkbox:checked + .checkbox {
  border-color: colors.$interactive-black;

  &::before {
    background-image: url('/assets/svgs/checkbox/selectedCheckbox.svg');
    background-repeat: no-repeat;
    background-size: 1.1rem 1.1rem;
    background-position: bottom left;
    animation: checkbox-check 0.2s cubic-bezier(0.4, 0, 0.23, 1) forwards;
  }
}

.hidden-checkbox:indeterminate + .checkbox {
  border-color: colors.$interactive-black;

  &::before {
    background-image: url('/assets/svgs/checkbox/indeterminateCheckbox.svg');
    background-repeat: no-repeat;
    background-size: 1.1rem 1.1rem;
    background-position: bottom left;
    animation: checkbox-check 0.2s cubic-bezier(0.4, 0, 0.23, 1) forwards;
  }
}

.hidden-checkbox:not(:disabled):active + .checkbox + .checkbox-label {
  background-color: colors.$interactive-light-grey;
}

.hidden-checkbox:disabled + .checkbox {
  border-color: colors.$interactive-disabled-grey;
}

.checkbox-label {
  @include typekit.p2;
  color: colors.$interactive-black;
  transition: background-color 0.3s ease-in-out 0s;

  &--disabled {
    color: colors.$interactive-disabled-grey;
  }
}
