@use 'styles/colors.module.scss' as colors;
@use 'styles/mixins.module.scss' as mixins;
@use 'src/styles/utils.module.scss' as utils;

$variant-mappings: (
  default: (
    color: colors.$interactive-black,
    background-color: colors.$interactive-light-grey,
  ),
  success: (
    color: colors.$system-success-green,
    background-color: colors.$system-success-tint-green,
  ),
  info: (
    color: colors.$interactive-kg-blue,
    background-color: colors.$interactive-soft-blue,
  ),
  error: (
    color: colors.$system-error-red,
    background-color: colors.$system-error-tint-red,
  ),
);

.info-content {
  @include mixins.flex-container(row, $align-items: center, $gap: 1rem);
  width: 100%;
  padding: 1rem;
  border-radius: utils.pxToRem(2);

  @each $variant, $properties in $variant-mappings {
    &--variant-#{$variant} {
      color: map-get($properties, color);
      background-color: map-get($properties, background-color);
      border: utils.pxToRem(1) solid map-get($properties, color);
    }
  }

  &__icon {
    width: 1rem;
    height: 1rem;

    @each $variant, $properties in $variant-mappings {
      &--variant-#{$variant} {
        color: map-get($properties, color);
      }
    }
  }

  &__text {
    color: colors.$interactive-black;
  }
}
