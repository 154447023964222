@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/breakpoints.module.scss' as breakpoints;
@use 'src/styles/utils.module.scss' as utils;
@use 'src/styles/typekit.module.scss' as typekit;
@use 'src/styles/mixins.module.scss' as mixins;

@mixin default {
  border: 0.0625rem solid colors.$interactive-black;
  background: colors.$text-white;
}

.long-input__container {
  @include mixins.flex-container(
    column,
    $align-items: flex-start,
    $gap: 0.25rem
  );
  height: fit-content;
  width: 100%;
}

.header {
  @include mixins.flex-container(row, space-between, $gap: 1rem);
  order: 1;
  width: 100%;

  &__label {
    align-self: stretch;
    text-align: left;
    color: colors.$text-medium-grey;
  }
}

.word-counter {
  color: colors.$text-medium-grey;
  text-align: end;
  transition: color 0.3s ease-out;

  &--error {
    color: colors.$system-error-red;
  }
}

.textarea {
  @include default();
  @include typekit.p2();
  order: 2;
  width: 100%;
  min-height: utils.pxToRem(167);
  border-radius: 0.125rem;
  padding: 0.72rem 1rem;
  color: colors.$text-black;
  resize: none;
  font-family: typekit.$default-font;
  transition:
    background 0.2s ease-in-out,
    border 0.2s ease-in-out,
    outline 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &::placeholder {
    color: colors.$text-medium-grey;
    opacity: 1;
  }

  &:hover {
    @include breakpoints.media-query-for('small-desktop') {
      outline: utils.pxToRem(1) solid colors.$interactive-black;
      background: colors.$background-tint-grey;
      cursor: pointer;
    }
  }

  &:focus {
    @include default();
    outline: 0.375rem solid colors.$borders-strokes-border-grey;
    cursor: pointer;

    @include breakpoints.media-query-for('small-desktop') {
      outline: none;
      cursor: text;
    }
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:disabled {
    background: colors.$interactive-disabled-grey;
    border: none;
    cursor: default;

    + label {
      color: colors.$text-disabled-text;
    }
  }

  &:disabled::placeholder {
    color: colors.$text-disabled-grey;
  }

  &:invalid,
  &--error {
    border: 0.125rem solid colors.$system-error-red;
    background: colors.$system-error-tint-red;
  }
}

.error-order {
  order: 3;
}
