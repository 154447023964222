@use 'src/styles/colors.module.scss' as colors;

.progress-bar {
  height: 0.375rem;
  background-color: colors.$interactive-light-grey;

  &__filler {
    height: 100%;
    border-radius: inherit;
    transition: width 0.2s ease-in;
    background-color: colors.$interactive-black;
  }
}

.kg-simple {
  .progress-bar__filler {
    background-color: colors.$interactive-kg-blue;
  }
}

.rounded {
  box-sizing: content-box;
  height: 0.34375rem;
  background-color: colors.$background-tint-grey;
  border-radius: 0.5625rem;
  border: 2px solid colors.$borders-strokes-border-grey;
  border-right-width: 1px;
}
