@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/utils.module.scss' as utils;

.body-container {
  @include mixins.flex-container(row, $gap: 1.5rem);
  padding: 1.5rem;
  width: 100%;
  background-color: colors.$background-white;
  border: utils.pxToRem(1) solid colors.$borders-strokes-border-grey;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.info {
  @include mixins.flex-container(row, $align-items: flex-start, $gap: 2.5rem);
  width: fit-content;

  &__top-bid {
    @include mixins.flex-container(column, $gap: 0);
    width: max-content;
    color: colors.$interactive-kg-blue;
  }

  &__title-text {
    color: colors.$text-medium-grey;
    width: max-content;
  }
}

.form {
  @include mixins.flex-container(row, $gap: 0.5rem);

  &__button {
    padding: 0.75rem utils.pxToRem(30);
    height: 100%;
    position: relative;
    right: 0;
    top: 0.25rem;
    width: 100%;
    flex-shrink: 0;
    margin-top: 1.5rem;

    &--large {
      padding: 0.5rem 1.5rem;
      height: none;
      flex-shrink: 1;
      margin-top: 0;
    }
  }

  &__input {
    width: 100%;
    align-self: flex-end;
    margin-top: 0.5rem;
  }

  &__title {
    color: colors.$text-medium-grey;
  }
}
