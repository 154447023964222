@use '/src/styles/colors.module.scss';
@use '/src/styles/breakpoints.module.scss';
@use '/src/styles/z-index.module.scss';
@use '/src/styles/mixins.module.scss';
@use 'styles/animations.module.scss' as keyframes;
@use 'styles/utils.module.scss';

.filters-bar {
  display: flex;
  align-items: center;
  height: fit-content;
  gap: 1.5rem;
  position: relative;
  justify-content: flex-end;

  @include breakpoints.media-query-for('small-desktop') {
    flex-direction: row;
    justify-content: space-between;
  }

  &__icon {
    width: utils.pxToRem(18);
    height: utils.pxToRem(18);
  }
}

@keyframes show-overflow {
  from {
    overflow: hidden;
  }
}

@mixin filters-transition($state) {
  transition: max-height 0.4s ease-in-out;

  .filter {
    opacity: $state;
    transition: opacity 0.6s ease-in-out;
  }
}

@mixin hidden-filters {
  max-height: 0;
  overflow: hidden;

  @include breakpoints.media-query-for('small-desktop') {
    overflow: visible;
  }
}

.filters {
  @include hidden-filters;
  @include mixins.flex-container($direction: column, $gap: 2rem);
  position: absolute;
  top: 4rem;
  width: 100%;
  min-width: calc(100vw - 2rem);
  z-index: z-index.$z-index-filters;
  background-color: colors.$background-tint-grey;

  &__search-bar {
    display: flex;
    width: 100%;
    height: fit-content;

    @include breakpoints.media-query-for('medium-desktop') {
      max-width: 30rem;
    }
  }

  &--open {
    @include filters-transition(1);
    max-height: 26rem;
    overflow: visible;
    animation: show-overflow 1s;
  }

  &--close {
    @include filters-transition(0);
    @include hidden-filters;
  }

  @include breakpoints.media-query-for('small-desktop') {
    flex-direction: row;
    gap: 1rem;
    position: relative;
    top: 0;
    width: fit-content;
    min-width: fit-content;
    justify-self: flex-end;
    padding: 0;
    max-height: fit-content;
  }
}

.filter {
  position: relative;

  &::after {
    content: '';
    height: 0.125rem;
    width: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: width 0.3s ease-in-out;
    background-color: colors.$interactive-black;
    animation: fadeOut 0.3s ease-in;
  }

  &:hover {
    &::after {
      animation: fadeIn 0.25s ease-in;
      width: 100%;
    }
  }
  @include breakpoints.media-query-for('medium-desktop') {
    width: 10.5rem;
  }

  &__background {
    background-color: transparent;
  }
}

.stories {
  background-color: transparent;
}
