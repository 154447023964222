@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/utils.module.scss' as utils;

.notification-container {
  position: relative;
  width: 100%;

  & a {
    display: flex;
  }
}

$icon-height: 1.5rem;

.notification {
  @include mixins.flex-container(row, left, center, 1rem);
  width: 100%;
  padding: 1rem;
  position: relative;

  &__content {
    @include mixins.flex-container(column, left, left, 0);
    margin: 0;
    padding: 0;
  }

  &:hover {
    background-color: colors.$interactive-light-grey;
  }

  &__title {
    max-width: 14.4375rem;
  }

  &__warning-icon {
    width: 2rem;
    height: 2rem;
  }

  &__close-icon {
    width: 1.5rem;
    height: $icon-height;
    border-radius: 6.25rem;
    position: absolute;
    right: 1rem;
    top: calc(50% - $icon-height / 2);

    &:focus {
      outline: 0.375rem solid colors.$interactive-light-grey;
    }

    &:hover {
      background-color: colors.$interactive-soft-blue;
      cursor: pointer;
    }
  }

  &__url {
    color: colors.$text-medium-grey;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.open-animation {
  animation-name: fade-in;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-duration: 0.4s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
