@use 'styles/colors.module.scss' as colors;
@use 'styles/utils.module.scss' as utils;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/constants.module.scss' as constants;
@use 'styles/z-index.module.scss' as z-index;
@use 'styles/animations.module.scss' as keyframes;
@use 'styles/mixins.module.scss' as mixins;

.banner {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  color: colors.$text-white;
  z-index: z-index.$z-index-winner-banner;
  animation: 0.4s ease-out 0s slideInFromBottom;

  @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
    position: static;
    animation: none;
  }

  &__image {
    background-position: 50% 0%;
    padding-top: 9rem;

    @include breakpoints.media-query-for(constants.$LARGE_MOBILE_BREAKPOINT) {
      background-position-y: 5%;
    }

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      background-position: 50% 0;
    }

    @include breakpoints.media-query-for(constants.$MEDIUM_DESKTOP_BREAKPOINT) {
      background-position-y: 10%;
    }
  }
}

.winner {
  &__container {
    padding: 1.5rem;
    position: absolute;
    top: initial;
    left: initial;
    @include mixins.flex-container(
      column,
      $align-items: flex-start,
      $gap: 1rem
    );
  }

  &__information {
    align-self: stretch;

    @include mixins.flex-container($align-items: center, $gap: 2rem);
  }

  &__text {
    @include mixins.flex-container(column);
  }

  &__profile {
    @include mixins.flex-container($align-items: center, $gap: 0.5rem);
  }
}

.profile-picture {
  border-radius: 50%;
  max-height: 100%;
  border: 0.125rem solid colors.$text-white;

  &__container {
    width: utils.pxToRem(50);
  }
}
