@use '/src/styles/colors.module.scss';
@use '/src/styles/mixins.module.scss';

.manage-items {
  @include mixins.flex-container($direction: column, $gap: 1.5rem);
  margin-top: 1.5rem;

  &__table-container {
    @include mixins.flex-container($direction: column, $gap: 1rem);
    background-color: colors.$interactive-white;
    padding: 1.5rem;
  }

  &__table {
    padding-bottom: 7rem;
  }
}
