@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/mixins.module.scss' as mixins;

.banner-container {
  @include mixins.flex-container(row, space-between, $gap: 0.5rem);
  padding: 1rem;
  width: 100%;
  height: fit-content;

  &__donor-picture {
    flex-shrink: 0;
    width: 2.5rem;
    height: 2.5rem;
    stroke-width: 0.125rem;
    stroke: colors.$interactive-white;
    border-radius: 6.25rem;
    overflow: hidden;
  }

  &__donor-info {
    @include mixins.flex-container(column, flex-start, flex-start, $gap: 0);
    padding: 0;
    width: 100%;
  }

  &__profile-picture {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  &__text {
    color: colors.$text-medium-grey;
  }
}
