@use 'styles/colors.module.scss' as colors;
@use 'styles/breakpoints.module.scss' as *;
@use 'styles/mixins.module.scss' as mixins;
@use 'styles/utils.module.scss' as utils;

@keyframes slideOpen {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideClose {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes openOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes closeOverlay {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.drawer {
  @include mixins.flex-container($justify-content: flex-end);

  &--open {
    animation: openOverlay 0.5s forwards ease-out;
  }

  &--close {
    animation: closeOverlay 0.5s forwards ease-in;
  }

  &__container {
    background-color: colors.$interactive-white;
    height: 100%;
    width: 100%;
    transform: translateX(100%);

    @include media-query-for('small-desktop') {
      width: utils.pxToRem(527);
    }

    &--open {
      animation: slideOpen 0.5s forwards ease-out;
    }

    &--close {
      animation: slideClose 0.5s forwards ease-in;
    }
  }
}
