@use 'styles/breakpoints.module.scss';
@use 'styles/utils.module.scss';
@use 'styles/colors.module.scss';
@use 'styles/animations.module.scss' as keyframes;
@use 'styles/mixins.module.scss';

.carousel {
  &__container {
    position: relative;
    width: 100%;

    &--closing {
      animation: 0.2s ease-out 0s fadeOut forwards;
    }
  }

  &__previews {
    position: absolute;
    bottom: utils.pxToRem(16.51);
    padding: 0 1rem;
    width: 100%;
    animation: 0.2s ease-out 0s slideInFromBottomFade;

    @include breakpoints.media-query-for('small-desktop') {
      padding: 0;
    }

    &--closing {
      animation: 0.2s ease-out 0s slideOutFromBottomFade forwards;
    }

    &--1-previews {
      max-width: utils.pxToRem(134);

      @include breakpoints.media-query-for('small-desktop') {
        width: utils.pxToRem(86);
      }
    }

    &--2-previews {
      max-width: utils.pxToRem(251);

      @include breakpoints.media-query-for('small-desktop') {
        width: utils.pxToRem(189);
      }
    }

    &--3-previews {
      max-width: utils.pxToRem(369);

      @include breakpoints.media-query-for('small-desktop') {
        width: utils.pxToRem(291);
      }
    }

    &--4-previews {
      max-width: utils.pxToRem(428);

      @include breakpoints.media-query-for('small-desktop') {
        width: utils.pxToRem(393);
      }
    }
  }

  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
  }
}

@mixin swiper-button-carousel {
  @include mixins.flex-container(
    $justify-content: center,
    $align-items: center
  );
  width: 3rem;
  height: utils.pxToRem(47);
  padding: utils.pxToRem(6) utils.pxToRem(6);
  border-radius: 6.25rem;
  border: utils.pxToRem(1) solid colors.$borders-strokes-border-grey;
  background: colors.$interactive-white;
  background-image: url('/assets/svgs/chevron-right.svg');
  background-position: center;
  background-size: 0.75rem;
  background-repeat: no-repeat;
  &::after {
    width: 2.25rem;
    height: utils.pxToRem(35);
    padding: utils.pxToRem(8) utils.pxToRem(8);
    content: '';
  }
}

.modal-carousel :global(.swiper-button-prev) {
  @include swiper-button-carousel();
  transform: scaleX(-1);
  left: utils.pxToRem(39);
}

.modal-carousel :global(.swiper-button-next) {
  @include swiper-button-carousel();
  right: utils.pxToRem(39);
}

.image-container {
  display: flex;
  justify-content: center;
  width: 70%;
  min-width: utils.pxToRem(396);
  animation: 0.2s ease-in 0s scaleUp30PercentToOrigin forwards;

  &--closing {
    animation: 0.2s ease-out 0s scaleDown30PercentFromOrigin forwards;
  }

  @include breakpoints.media-query-for('small-desktop') {
    width: 41.75rem;
  }
}
