@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/utils.module.scss' as utils;

.body-container {
  @include mixins.flex-container(column, $gap: 1rem);
  padding: 1.5rem;
  width: 100%;
  background-color: colors.$background-white;
  border: utils.pxToRem(1) solid colors.$borders-strokes-border-grey;

  &__title {
    color: colors.$text-black;
  }
}

.info {
  @include mixins.flex-container(row, $align-items: flex-start, $gap: 2.5rem);
  width: fit-content;

  &__top-bid {
    @include mixins.flex-container(column, $gap: 0);
    width: max-content;
    color: colors.$interactive-kg-blue;
  }

  &__countdown-container {
    @include mixins.flex-container(column, $gap: 0);
    color: colors.$text-black;
    width: 100%;
  }

  &__countdown {
    width: utils.pxToRem(250);
  }

  &__title-text {
    color: colors.$text-medium-grey;
    width: max-content;
  }
}

.form {
  @include mixins.flex-container(row, $gap: 0.5rem);

  &__title {
    color: colors.$text-medium-grey;
  }

  &__button {
    padding: 0.75rem;
    height: 3rem;
    position: relative;
    right: 0;
    top: 0.25rem;
    width: 7.56rem;
    flex-shrink: 0;
  }

  &__input {
    width: 100%;
    align-self: flex-end;
  }
}
