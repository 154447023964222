@use 'styles/constants.module.scss' as constants;
@use 'styles/colors.module.scss' as colors;
@use 'styles/utils.module.scss' as utils;
@use 'components/organisms/UserTable/userTable.module.scss' as userTable;
@use 'components/organisms/AuctionItemTable/auctionItemTable.module.scss' as
  itemTable;
@use 'components/organisms/BiddingTable/biddingTable.module.scss' as
  biddingTable;

.table {
  &__cell {
    display: flex;
    width: utils.pxToRem(165);
    height: utils.pxToRem(80);
    padding: utils.pxToRem(16);
    align-items: center;
    background: colors.$background-white;

    @include biddingTable.bidding-table-cell-modifiers;
    @include userTable.user-table-cell-modifiers;
    @include itemTable.item-table-cell-modifiers;

    &-input {
      @include userTable.user-table-cell-input-modifiers;
    }
  }
}
