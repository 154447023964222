@use 'src/styles/typekit.module.scss' as typekit;
@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/utils.module.scss' as utils;
@use '/src/styles/breakpoints.module.scss';
@use '/src/styles/constants.module.scss';

.pagination-container {
  @include mixins.flex-container(row, center, center);
  position: relative;
  padding: 2rem 1rem 0;

  width: 100%;
  height: 100%;

  @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
    padding: 3rem 3.5rem 0;
  }
}

.pagination {
  @include mixins.flex-container(
    row,
    flex-start,
    center,
    $gap: utils.pxToRem(10)
  );
  padding: 0;
  margin: 0;
  position: relative;
  list-style-type: none;

  &__ellipsis {
    padding: 0 0.5rem;
    user-select: none;
    pointer-events: none;
  }

  &__page-number {
    font-family: inherit;
    border: none;
    background-color: transparent;
    padding: 0 0.5rem;
    outline: none;
    color: colors.$interactive-black;
    width: 1.5rem;
    height: 1.5rem;
    @include typekit.p2;
    transition:
      color 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;

    &:hover {
      color: colors.$interactive-kg-blue;
      text-decoration: none;
      cursor: pointer;
    }

    &:focus,
    &:active,
    &--selected {
      color: colors.$interactive-kg-blue;
      @include typekit.d5;

      .pagination__number-text {
        @include typekit.d5;
      }
    }
  }

  &__chevron-container {
    @include mixins.flex-container(row, center, center);
    width: 2rem;
    height: 2rem;
    padding: utils.pxToRem(7);
  }

  &__chevron-icon {
    box-sizing: content-box;
    width: utils.pxToRem(18);
    height: utils.pxToRem(18);
    padding: 0.25rem;
    border-radius: 50%;
    outline: none;
    transition:
      background-color 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out,
      width 0.2s ease-in-out,
      height 0.2s ease-in-out,
      border 0.2s ease-in-out;

    &--left-side {
      path {
        transform: translate(utils.pxToRem(-2), 0);
      }
    }

    &--right-side {
      path {
        transform: translate(utils.pxToRem(2), 0);
      }
    }

    &:hover,
    &:active,
    &:focus {
      width: utils.pxToRem(16);
      height: utils.pxToRem(16);
    }

    &:hover {
      border: utils.pxToRem(1) solid colors.$interactive-black;
      cursor: pointer;
    }

    &:active {
      border: utils.pxToRem(1) solid colors.$interactive-black;
      background-color: colors.$interactive-light-grey;
      cursor: pointer;
    }

    &:focus {
      box-shadow: 0 0 0 utils.pxToRem(6) colors.$interactive-light-grey;
    }
  }
}
