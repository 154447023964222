@use 'styles/colors.module.scss' as colors;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/typekit.module.scss' as typography;
@use 'styles/utils.module.scss' as utils;
@use 'styles/constants.module.scss' as constants;

// Mobile Styles
$mobile-custom-input-width: 100%;
$mobile-custom-input-height: utils.pxToRem(47);
$mobile-button-padding: utils.pxToRem(10);

// Desktop Styles
$desktop-custom-input-width: 100%;
$desktop-custom-single-input-gap: utils.pxToRem(16);

// Shared Styles
$next-arrow-width: utils.pxToRem(16);
$next-arrow-height: utils.pxToRem(42);
$next-arrow-padding: utils.pxToRem(13) utils.pxToRem(0);
$next-arrow-gap: utils.pxToRem(10);
$custom-input-gap: utils.pxToRem(8);
$custom-input-padding: utils.pxToRem(0) utils.pxToRem(16);
$calendar-icon-width: utils.pxToRem(22);
$calendar-icon-height: utils.pxToRem(22);

.required-mark {
  color: colors.$system-error-red;
}

.date-picker {
  &__custom-input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 0;
    padding: $custom-input-padding;
    gap: $custom-input-gap;
    background: colors.$background-tint-grey;
    height: $mobile-custom-input-height;
    width: $mobile-custom-input-width;
    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      width: $desktop-custom-input-width;
    }

    &--single {
      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        gap: $desktop-custom-single-input-gap;
      }
    }

    &--range {
      align-self: stretch;
    }

    &--disabled {
      background-color: colors.$interactive-disabled-grey;
    }

    &-calendar-icon {
      width: $calendar-icon-width;
      height: $calendar-icon-height;
      &--disabled {
        color: colors.$text-disabled-text;
      }
    }

    &-next-arrow {
      display: flex;
      align-items: center;
      width: $next-arrow-width;
      height: $next-arrow-height;
      padding: $next-arrow-padding;
      gap: $next-arrow-gap;
      color: colors.$borders-strokes-border-grey;
    }

    &-button {
      font-family: typography.$default-font;
      @include typography.d5;
      display: flex;
      flex: 1 0 0;
      height: auto;
      justify-content: center;
      color: colors.$interactive-black;
      align-items: center;
      align-self: stretch;
      border: none;
      gap: $mobile-button-padding;
      background: colors.$background-tint-grey;
      transition:
        background 0.3s ease-in-out 0s,
        background-color 0.3s ease-in-out;

      &:hover {
        background: colors.$interactive-light-grey;
        text-decoration: underline;
      }

      &:disabled {
        background-color: colors.$interactive-disabled-grey;
        color: colors.$text-disabled-text;
        text-decoration: none;
      }
    }
  }
}
