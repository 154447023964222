@use '/src/styles/mixins.module.scss';
@use '/src/styles/colors.module.scss';

.no-items {
  @include mixins.flex-container(
    $direction: column,
    $justify-content: center,
    $align-items: center
  );
  padding: 3rem;

  &__button {
    margin-top: 1.5rem;
  }

  &--light {
    color: colors.$text-medium-grey;
  }
}
