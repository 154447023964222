@use 'styles/mixins.module.scss' as mixins;
@use 'styles/colors.module.scss' as colors;
@use 'styles/typekit.module.scss' as typekit;
@use 'styles/utils.module.scss' as utils;
@use 'styles/animations.module.scss';

.container {
  @include mixins.flex-container(
    $justify-content: center,
    $align-items: center,
    $gap: utils.pxToRem(10)
  );
  border-radius: utils.pxToRem(2);
  background-color: colors.$interactive-white;
  border: 1px solid colors.$text-disabled-text;
  width: fit-content;
  color: colors.$text-black;
  transition:
    color 0.2s ease-in-out 0s,
    background-color 0.2s ease-in-out 0s,
    border 0.2s ease-in-out 0s,
    box-shadow 0.2s ease-in-out;

  &--edit-small {
    @extend .container;
    gap: utils.pxToRem(4);
    padding: utils.pxToRem(2) utils.pxToRem(8);
  }

  &--no-edit-small {
    @extend .container;
    padding: utils.pxToRem(2) utils.pxToRem(4);
  }

  &--large {
    @extend .container;
    min-height: utils.pxToRem(40);
    padding: utils.pxToRem(8) utils.pxToRem(16);
  }

  &--input {
    @extend .container;
    padding: 0;
  }

  &:hover {
    border-color: colors.$interactive-black;
    cursor: pointer;
  }

  &:active {
    border-color: colors.$interactive-black;
    background-color: colors.$interactive-black;
    color: colors.$interactive-white;
  }

  &:focus {
    box-shadow: 0 0 0 utils.pxToRem(6) colors.$interactive-light-grey;
  }

  &:focus-within {
    box-shadow: 0 0 0 utils.pxToRem(6) colors.$interactive-light-grey;
  }
}

.icon {
  width: utils.pxToRem(14);
  height: utils.pxToRem(14);
  color: inherit;
}

.input {
  @include typekit.p2;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 0.125rem;
  background: colors.$background-white;
  transition:
    color 0.3s ease-in-out,
    outline 0.3s ease-in-out,
    border 0.3s ease-in-out,
    background 0.3s ease-in-out,
    border-bottom 0.3s ease-in-out;

  &--small {
    @extend .input;
    padding: utils.pxToRem(2) utils.pxToRem(8);
  }

  &--large {
    @extend .input;
    padding: utils.pxToRem(8) utils.pxToRem(16);
  }
}
