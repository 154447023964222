@use 'src/styles/breakpoints.module.scss' as breakpoints;
@use 'src/styles/mixins.module.scss' as mixins;

@mixin grid() {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: fit-content;
}

.cards-container {
  @include mixins.flex-container(column);
  height: fit-content;
  width: 100%;

  &--fit-width {
    width: fit-content;
  }

  &__content {
    @include grid();

    &--campaign {
      gap: 1rem;

      @include breakpoints.media-query-for('small-desktop') {
        grid-template-columns: 1fr 1fr;
      }
    }

    &--item {
      gap: 0.5rem;

      @include breakpoints.media-query-for('small-desktop') {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }

      @include breakpoints.media-query-for('medium-desktop') {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
