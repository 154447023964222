@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/typekit.module.scss' as typekit;

.donation-progress {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 0.25rem;

  &__donation-info {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-self: stretch;

    &--kg-blue {
      color: colors.$interactive-kg-blue;
    }

    &--grey {
      color: colors.$text-medium-grey;
      opacity: 0;
    }
  }

  &__progress-bar {
    opacity: 0;
  }
}

.campaign-finished {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 1.125rem;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;

    &--goal {
      opacity: 0;
    }
  }

  &__secondary-text {
    color: colors.$text-medium-grey;
  }
}
