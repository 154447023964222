$ratios: (
  16: 9,
  9: 16,
  4: 3,
  3: 4,
  1: 1,
);

.image {
  max-width: 100%;
  height: auto;

  &--with-ratio {
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    @each $width, $height in $ratios {
      &--#{$width}-#{$height} {
        padding-top: ($height/ $width) * 100%;
      }
    }
  }
}
