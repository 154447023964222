@use 'styles/utils.module.scss';
@use 'styles/mixins.module.scss';
@use 'styles/colors.module.scss';

.img {
  object-fit: cover;
  object-position: center;
  transition: transform 0.5s;
  height: 100%;
  width: 100%;
}

.img-cover {
  width: 100%;
  height: 100%;
}

@mixin swiper-button-slider {
  @include mixins.flex-container(row, center, center);
  width: 2rem;
  height: 2rem;
  top: 8.5rem;
  padding: utils.pxToRem(5.8976) utils.pxToRem(6);
  border-radius: 6.25rem;
  border: utils.pxToRem(1) solid colors.$borders-strokes-border-grey;
  background: colors.$interactive-white;
  background-image: url('/assets/svgs/chevron-right.svg');
  background-position: center;
  background-size: 0.75rem;
  background-repeat: no-repeat;
  &::after {
    width: 2.25rem;
    height: utils.pxToRem(35.379);
    padding: utils.pxToRem(7.739) utils.pxToRem(7.8752);
    content: '';
  }
}

.image-carousel :global(.swiper-button-prev) {
  @include swiper-button-slider();
  left: 1rem;
  transform: scaleX(-1);
  position: absolute;
  opacity: 0;
}

.image-carousel :global(.swiper-button-next) {
  @include swiper-button-slider();
  right: 1rem;
  opacity: 0;
}
