$breakpoints: (
  'small-mobile': 428px,
  'large-mobile': 600px,
  'small-tablet': 768px,
  'small-desktop': 905px,
  'medium-desktop': 1280px,
  'large-desktop': 1440px,

  // special breakpoints
  'user-table': 905px,
  'item-table': 1260px,
);

@mixin media-query-for($breakpoint) {
  @if map-has-key($map: $breakpoints, $key: $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @error 'There is no breakpoint named `#{$breakpoint}`. ' 
        + 'These are the existing breakpoints: #{map-keys($breakpoints)}';
  }
}
