@use '/src/styles/typekit.module.scss';
@use '/src/styles/breakpoints.module.scss';
@use '/src/styles/colors.module.scss';
@use '/src/styles/mixins.module.scss';

.donation-section {
  &__progress {
    @include mixins.flex-container(
      $direction: column,
      $justify-content: space-evenly,
      $gap: 0.25rem
    );
    width: 100%;
  }

  &__button {
    @include typekit.d6;
    display: flex;
    padding: 0.5rem 1.5rem;
    gap: 0.625rem;

    @include breakpoints.media-query-for('small-desktop') {
      width: 12.25rem;
    }
  }

  &__text {
    color: colors.$text-medium-grey;
  }
}
