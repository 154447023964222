@use '/src/styles/colors.module.scss';
@use '/src/styles/mixins.module.scss';
@use '/src/styles/typekit.module.scss';

.cropped-text {
  @include mixins.flex-container($direction: column);
  width: 100%;
  height: fit-content;
  position: relative;

  &__text {
    @include typekit.p2;
    color: colors.$text-black;
    flex: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    &--hidden {
      visibility: hidden;
      position: absolute;
    }
  }

  &__button {
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;

    &:visited {
      color: colors.$text-black;
    }
  }
}
