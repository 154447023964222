// Please add your z-index in descending numerical order
// Name your variables with prefix z-index-
// Preferably leave a 50-100 gap between each z-index, in case we need to add more z-index in between

$z-index-notification-wrapper: 1100;
$z-index-modal: 999;
$z-index-form-modal: 998;
$z-index-drawer: 998;
$z-index-notification: 980;
$z-index-hero: 975;
$z-index-filters: 970;
$z-index-dropdown-options: 960;
$z-index-date-picker: 955;
$z-index-floating-button: 950;
$z-index-actions-menu: 902;
$z-index-actions-menu-action-focus: 901;
$z-index-actions-menu-action: 900;
$z-index-tooltip: 850;
$z-index-winner-banner: 100;
$z-index-hero-actions: 20;
$z-index-hero-info: 10;
$z-index-form-modal-bottom: 3;
$z-index-hero-overlay: 1;
$z-index-file-upload-btn: 1;
