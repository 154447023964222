@use 'src/styles/typekit.module.scss' as typekit;

.d1 {
  @include typekit.d1;
}

.d2 {
  @include typekit.d2;
}

.d3 {
  @include typekit.d3;
}

.d4 {
  @include typekit.d4;
}

.d5 {
  @include typekit.d5;
}

.d6 {
  @include typekit.d6;
}

.p1 {
  @include typekit.p1;
}

.p2 {
  @include typekit.p2;
}

.p3 {
  @include typekit.p3;
}

.p4 {
  @include typekit.p4;
}

.s2 {
  @include typekit.s2;
}
