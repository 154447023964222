@use 'styles/breakpoints.module.scss' as *;
@use 'styles/colors.module.scss' as colors;
@use 'styles/mixins.module.scss' as mixins;

@mixin global-checkbox-singular($width) {
  @include mixins.flex-container(column, $align-items: flex-start);
  width: $width;
  height: auto;
}

.checkbox-group {
  @include mixins.flex-container(
    column,
    $align-items: flex-start,
    $gap: 0.25rem
  );
  align-self: stretch;
  border: 0;
  padding: 0;
  margin: 0;

  &__container {
    @include mixins.flex-container($align-items: flex-start);
    row-gap: 0.25rem;
    column-gap: 1rem;
    align-self: stretch;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding-left: 0;
    margin: 0;
  }

  &__label {
    padding: 0;
    align-self: stretch;
    text-align: left;
    color: colors.$text-medium-grey;
  }

  &__singular-container {
    @include global-checkbox-singular(10.375rem);

    @include media-query-for('large-mobile') {
      @include global-checkbox-singular(9.25rem);
    }
  }
}
