@use '/src/styles/colors.module.scss';
@use '/src/styles/mixins.module.scss';

@mixin button-color-settings($color) {
  background-color: $color;

  &:hover,
  &:focus {
    background-color: colors.$interactive-white;
    border: 1px solid $color;

    svg {
      color: $color;
    }
  }
}

.approval-actions {
  @include mixins.flex-container();
  gap: 1rem;
  width: fit-content;
  height: fit-content;
  padding-right: 1rem;

  &__button {
    @include mixins.flex-container(
      $justify-content: center,
      $align-items: center
    );
    width: 2.5rem;
    height: 2.5rem;
    border: none;
    border-radius: 0.125rem;
    cursor: pointer;

    &--approve {
      @include button-color-settings(colors.$system-success-green);
    }

    &--decline {
      @include button-color-settings(colors.$system-error-red);
    }

    &:focus {
      outline: 6px solid colors.$interactive-light-grey;
    }
  }

  &__button-text {
    @include mixins.flex-container();
    align-content: center;
    pointer-events: none;
  }

  &__icon {
    @include mixins.flex-container($justify-content: center);

    svg {
      width: 0.875rem;
      height: 0.875rem;
      align-self: center;
    }
  }
}
