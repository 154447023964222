@use '/src/styles/colors.module.scss';
@use '/src/styles/breakpoints.module.scss';

@mixin slope-image {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 32% 100%);
  object-fit: cover;
}

@mixin flex-container(
  $direction: row,
  $justify-content: flex-start,
  $align-items: stretch,
  $gap: 0,
  $flex-shrink: 1
) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
  gap: $gap;
  $flex-shrink: $flex-shrink;
}

@mixin table-scroll {
  width: 100%;
  overflow-x: scroll;
}

@mixin button-with-icon--icon-hover($color) {
  &:hover,
  &:focus {
    svg {
      color: $color;
      cursor: pointer;
    }
  }
}

@mixin scroll-bar-border {
  border-radius: 0.25rem;
}

@mixin table-scroll-bar-styles($breakpoint) {
  ::-webkit-scrollbar {
    height: 0.5rem;

    &:vertical {
      display: none;
    }
  }

  ::-webkit-scrollbar-thumb {
    @include scroll-bar-border;
    background: colors.$text-disabled-grey;
  }

  ::-webkit-scrollbar-track {
    @include scroll-bar-border;
    background: colors.$interactive-disabled-grey;

    @include breakpoints.media-query-for($breakpoint) {
      display: none;
    }
  }
}
