@use 'styles/colors.module.scss' as colors;
@use 'src/styles/utils.module.scss' as utils;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/constants.module.scss' as constants;
@use 'styles/animations.module.scss' as keyframes;
@use 'styles/z-index.module.scss' as *;

$tooltip-margin: utils.pxToRem(16);
$mobile-tooltip-width: 179;
$mobile-tooltip-ends-width: 184;
$desktop-tooltip-width: 208;
$desktop-tooltip-ends-width: 214;
$tooltip-desktop-offset: utils.pxToRem($desktop-tooltip-ends-width / 2);
$tooltip-mobile-offset: utils.pxToRem($mobile-tooltip-ends-width / 2);

.tooltip {
  &__wrapper {
    display: inline-block;
    position: relative;
    border: none;
    background-color: transparent;
    height: auto;
  }

  &__container {
    display: flex;
    position: absolute;
    z-index: $z-index-tooltip;

    &--bottom {
      width: utils.pxToRem($mobile-tooltip-ends-width);
      top: calc(100% + $tooltip-margin);
      left: calc(50% - $tooltip-mobile-offset);
      animation: 0.3s ease-out 0s slideInFromBottomFade;

      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        width: utils.pxToRem($desktop-tooltip-ends-width);
        left: calc(50% - $tooltip-desktop-offset);
      }
    }

    &--bottom-close {
      top: calc(100% + $tooltip-margin);
      left: calc(50% - $tooltip-mobile-offset);
      animation: 0.3s ease-out 0s slideOutFromBottomFade;
      opacity: 0;

      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        left: calc(50% - $tooltip-desktop-offset);
      }
    }

    &--top {
      width: utils.pxToRem($mobile-tooltip-ends-width);
      bottom: calc(100% + $tooltip-margin);
      left: calc(50% - $tooltip-mobile-offset);
      animation: 0.3s ease-out 0s slideInFromTopFade;

      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        width: utils.pxToRem($desktop-tooltip-ends-width);
        left: calc(50% - $tooltip-desktop-offset);
      }
    }

    &--top-close {
      bottom: calc(100% + $tooltip-margin);
      left: calc(50% - $tooltip-mobile-offset);
      animation: 0.3s ease-out 0s slideOutFromTopFade;
      opacity: 0;

      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        left: calc(50% - $tooltip-desktop-offset);
      }
    }

    &--left {
      right: calc(100% + $tooltip-margin);
      animation: 0.3s ease-out 0s slideInFromLeftFade;
      top: 0;
    }

    &--left-close {
      right: calc(100% + $tooltip-margin);
      top: 0;
      animation: 0.3s ease-out 0s slideOutFromLeftFade;
      opacity: 0;
    }

    &--right {
      left: calc(100% + $tooltip-margin);
      animation: 0.3s ease-out 0s slideInFromRightFade;
      top: 0;
    }

    &--right-close {
      left: calc(100% + $tooltip-margin);
      top: 0;
      animation: 0.3s ease-out 0s slideOutFromRightFade;
      opacity: 0;
    }

    &-info {
      display: flex;
      position: relative;
      border-radius: utils.pxToRem(2);
      flex: 1 0 0;
      padding: utils.pxToRem(8) utils.pxToRem(16);
      width: utils.pxToRem($mobile-tooltip-width);
      align-items: center;
      background: colors.$interactive-black;
      color: colors.$interactive-white;

      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        width: utils.pxToRem($desktop-tooltip-width);
      }

      &-text {
        max-width: 100%;
        overflow: hidden;
        display: block;
        text-align: justify;
        color: colors.$interactive-white;
      }
    }
  }

  &-popper-arrow {
    position: absolute;
    height: 0;
    border-style: solid;
    border-width: utils.pxToRem(5) utils.pxToRem(5) 0;
    border-color: colors.$interactive-black transparent;

    &-icon {
      position: absolute;
      color: colors.$interactive-black;
      width: utils.pxToRem(14);

      &--bottom {
        left: 50%;
        top: 0;
        transform: translate(-50%, calc(-100% + utils.pxToRem(5)));
      }

      &--top {
        left: 50%;
        bottom: 0;
        transform: translate(-50%, calc(-100% + utils.pxToRem(27.5)))
          rotate(180deg);
      }

      &--left {
        right: 0;
        top: utils.pxToRem(10);
        transform: rotate(90deg);

        svg {
          transform: translateY(calc(100% - utils.pxToRem(15.5)));
        }
      }

      &--right {
        left: 0;
        top: utils.pxToRem(10);
        transform: rotate(-90deg);

        svg {
          transform: translateY(calc(100% - utils.pxToRem(15.5)));
        }
      }
    }
  }
}
