@use 'styles/mixins.module.scss' as mixins;
@use 'styles/colors.module.scss' as colors;
@use 'styles/utils.module.scss' as utils;
@use 'styles/animations.module.scss';

.container {
  @include mixins.flex-container(
    $align-items: flex-start,
    $gap: utils.pxToRem(16)
  );
  width: 100%;
  flex-wrap: wrap;
}

.button {
  gap: 0;
  padding: utils.pxToRem(7) utils.pxToRem(13);
  width: auto;
}

.input {
  width: 100%;

  &__container {
    animation: extendCategoryInput 0.1s ease-in-out forwards;

    &--closing {
      animation: shrinkCategoryInput 0.1s ease-in-out forwards;
    }
  }
}
