@use 'styles/colors.module.scss' as colors;
@use 'styles/constants.module.scss' as constants;
@use 'styles/breakpoints.module.scss' as *;

.bg-color {
  background-color: colors.$background-tint-grey;
}

.page-container {
  padding: 0 1rem;

  @include media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
    padding: 0 2rem;
  }

  @include media-query-for(constants.$MEDIUM_DESKTOP_BREAKPOINT) {
    padding: 0 3.5rem;
  }

  @include media-query-for(constants.$LARGE_DESKTOP_BREAKPOINT) {
    max-width: 83rem;
    padding: 0;
    margin: 0 auto;
  }

  &--wide {
    max-width: 108rem;
    padding: 0 3.5rem;
    margin: 0 auto;
  }
}
