@use 'styles/typekit.module.scss' as typography;
@use 'styles/colors.module.scss' as colors;
@use 'styles/utils.module.scss' as utils;
@use 'components/organisms/UserTable/userTable.module.scss' as userTable;
@use 'components/organisms/AuctionItemTable/auctionItemTable.module.scss' as
  itemTable;
@use 'components/organisms/BiddingTable/biddingTable.module.scss' as
  biddingTable;

.table {
  &__header-cell {
    @include typography.p2;
    color: colors.$text-medium-grey;
    display: flex;
    width: utils.pxToRem(165);
    height: utils.pxToRem(56);
    padding: utils.pxToRem(16);
    align-items: center;
    border: none;

    @include userTable.user-table-cell-modifiers;
    @include itemTable.item-table-cell-modifiers;
    @include biddingTable.bidding-table-header-modifiers;
  }
}
