@use 'styles/colors.module.scss' as colors;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/utils.module.scss' as utils;
@use 'styles/mixins.module.scss' as mixins;
@use '/src/styles/constants.module.scss' as constants;

.auction-item-details {
  &__content {
    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      @include mixins.flex-container($gap: 2rem);
    }
  }

  &__page-container {
    height: 100%;
    min-height: 50rem;
  }

  &__bidding {
    width: 100%;

    &-history {
      @include mixins.flex-container($direction: column, $gap: 1rem);
      border: 1px solid colors.$borders-strokes-border-grey;
      background-color: colors.$background-white;
      padding: 1.5rem;

      &--winner-spacing {
        margin-bottom: 8.75rem;
      }

      &--active-bid-spacing {
        margin-bottom: 6.5rem;
      }

      &--deleted-spacing {
        margin-bottom: auto;
      }
    }

    &-info {
      @include breakpoints.media-query-for(
        constants.$SMALL_DESKTOP_BREAKPOINT
      ) {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__card {
    margin-bottom: 1rem;
  }

  &__info-message {
    margin-bottom: 1rem;

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      margin-bottom: 1.5rem;
    }
  }
}
