@use '/src/styles/mixins.module.scss' as mixins;
@use '/src/styles/colors.module.scss' as colors;
@use '/src/styles/typekit.module.scss' as typekit;
@use '/src/styles/breakpoints.module.scss' as *;
@use '/src/styles/utils.module.scss' as *;
@use '/src/styles/z-index.module.scss' as *;
@use 'styles/animations.module.scss' as keyframes;

@mixin placeholder-color($color) {
  &::placeholder {
    color: $color;
  }
}

@mixin dropdown-border($border-color) {
  border-radius: 0.125rem;
  border: 1px solid $border-color;
}

.dropdown-container {
  @include mixins.flex-container(column, $gap: 0.25rem);
  border: none;
  position: relative;
  width: 100%;
  background-color: colors.$interactive-white;
}

.dropdown {
  @include typekit.p2;
  @include placeholder-color(colors.$text-medium-grey);
  @include dropdown-border(colors.$interactive-black);
  font-family: inherit;
  color: colors.$interactive-black;
  width: 100%;
  height: 3rem;
  background: colors.$background-white;
  padding: 0.72rem 1rem;
  background-color: transparent;
  padding-right: 2.5rem;
  transition:
    border-width 0.2s ease-in-out,
    outline 0.2s ease-in-out,
    background 0.2s ease-in-out,
    color 0.2s ease-in-out;
  outline: 0;

  &:hover {
    @include placeholder-color(colors.$interactive-black);
    outline: pxToRem(1) solid colors.$interactive-black;
    cursor: pointer;
  }

  &:focus {
    outline: 6px solid colors.$interactive-light-grey;

    &:hover {
      border-width: 1px;
    }
  }

  &:disabled {
    @include placeholder-color(colors.$text-disabled-text);
    color: colors.$text-disabled-text;
    background-color: colors.$interactive-disabled-grey;
    border: none;
    cursor: auto;
    outline: none;
  }

  &__label {
    color: colors.$text-medium-grey;

    &--disabled {
      color: colors.$text-disabled-text;
    }
  }

  &__input-container {
    position: relative;
    width: 100%;
  }

  &--error {
    border: 0.125rem solid colors.$system-error-red;
    background: colors.$system-error-tint-red;
  }

  &--floating {
    @include placeholder-color(colors.$interactive-black);
    @include typekit.d6;
    height: 2.5rem;
    padding: 0.25rem;
    border: none;

    &:hover {
      outline: none;
      border: none;
      text-decoration: underline;
    }

    &:active {
      background-color: colors.$background-tint-grey;
      text-decoration: underline;
      outline: none;
    }

    &:focus {
      text-decoration: none;

      &:active {
        text-decoration: underline;
      }
    }

    &:disabled {
      @include placeholder-color(colors.$text-disabled-text);
      background-color: colors.$background-white;
      text-decoration: none;
    }

    @include media-query-for('small-desktop') {
      &:focus {
        outline: none;
        background-color: colors.$background-tint-grey;
      }
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    background-color: colors.$background-white;
    list-style-type: none;
    z-index: $z-index-dropdown-options;
    position: absolute;
    top: 100%;
    margin-top: 0.5rem;
    width: 100%;
    @include dropdown-border(colors.$interactive-black);
    padding: 0.5rem 0;
    animation: 0.3s ease-out 0s extendTopToBottom;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }

    &--closing {
      animation: 0.3s ease-out 0s shrinkTopToBottom forwards;
    }

    &--floating {
      min-width: 100%;
      width: max-content;

      @include media-query-for('small-desktop') {
        width: 100%;
      }
    }

    &--scrollable {
      scrollbar-color: colors.$interactive-kg-blue
        colors.$interactive-disabled-grey;
      scrollbar-width: thin;

      @include media-query-for('small-desktop') {
        max-height: pxToRem(270);
        overflow-y: auto;
      }

      &::-webkit-scrollbar {
        width: 0.25rem;

        &-track {
          border-radius: pxToRem(6);
          background-color: colors.$interactive-disabled-grey;
        }

        &-thumb {
          border-radius: pxToRem(6);
          background-color: colors.$interactive-kg-blue;
        }
      }
    }

    &--filter {
      padding: 0;

      @include dropdown-border(colors.$borders-strokes-border-grey);
    }
  }

  &__option {
    @include typekit.p2;
    padding: 1rem 2rem;
    transition: background-color 0.3s ease-in-out;

    &:hover,
    &:active {
      background-color: colors.$background-tint-grey;
    }

    &--selected {
      background-color: colors.$interactive-light-grey;
    }

    &--multiple {
      padding: 0;
    }
  }

  &__checkbox {
    padding: 1rem 1.5rem;

    &:focus-within {
      margin-left: 0;
      box-shadow: none;
      border-radius: 0;
      padding-left: 1.5rem;
    }
  }

  &__icon {
    position: absolute;
    right: 1rem;
    padding: 0.19rem;
    top: 0.72rem;
    pointer-events: none;

    &--floating {
      top: 0.5rem;
      right: 0.5rem;
    }

    &--spinning {
      animation: 0.2s ease-out 0s spin180DegreesClockwiseToOrigin;
    }

    &--spinning-close {
      animation: 0.2s ease-out 0s spin180DegreesCounterClockwiseToOrigin;
    }
  }
}
