@use 'src/styles/colors.module.scss' as colors;
@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/breakpoints.module.scss' as breakpoints;

.banner-content {
  @include mixins.flex-container(row, space-between, $gap: 0.5rem);
  width: 100%;
  height: fit-content;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  background-color: colors.$interactive-soft-blue;

  @include breakpoints.media-query-for('small-desktop') {
    padding: 1rem;
  }

  &__winner {
    @include mixins.flex-container(row, space-between, $gap: 0.5rem);
  }

  &__medal-icon {
    align-self: center;
    width: 2rem;
    height: 2rem;
  }

  &__text {
    color: colors.$text-medium-grey;
  }

  &__column-display {
    @include mixins.flex-container(column);
  }
}
