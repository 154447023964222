@import 'src/styles/colors.module.scss';

.expiredmessage {
  display: flex;
  flex-direction: column;

  &__text {
    align-self: flex-end;
  }
}
