@use '/src/styles/colors.module.scss';
@use '/src/styles/utils.module.scss';
@use '/src/styles/mixins.module.scss';
@use '/src/styles/breakpoints.module.scss';
@use '/src/styles/constants.module.scss';

@mixin column-size($size) {
  min-width: utils.pxToRem($size);
  width: utils.pxToRem($size);
}

@mixin item-column-width($size) {
  padding: 0;
  min-width: $size;
  width: $size;
}

@mixin item-table-cell-modifiers {
  &--item-images {
    @include column-size(89);
  }

  &--item-name {
    @include column-size(175);
  }

  &--item-description {
    width: utils.pxToRem(308);
    min-height: utils.pxToRem(80);
    height: fit-content;

    @include breakpoints.media-query-for(
      constants.$ITEM_TABLE_SPECIAL_BREAKPOINT
    ) {
      min-width: utils.pxToRem(228);
      width: 100%;
    }
  }

  &--item-owner {
    @include column-size(110);
  }

  &--item-starting-bid {
    @include column-size(122);
  }

  &--item-date-created {
    @include column-size(122);
  }

  &--item-category {
    @include column-size(106);
    word-break: break-word;
  }

  &--item-status {
    @include column-size(112);
  }

  &--item-id {
    @include item-column-width(fit-content);

    &__empty-header {
      @include item-column-width(utils.pxToRem(56));
    }

    &__approval-header {
      @include item-column-width(utils.pxToRem(112));
    }
  }
}

.item-table {
  &__image-cell {
    width: utils.pxToRem(57);
    height: utils.pxToRem(57);
    object-fit: cover;
  }

  &__text-cell {
    color: colors.$text-black;
  }

  &__container {
    @include mixins.table-scroll-bar-styles(
      constants.$MEDIUM_DESKTOP_BREAKPOINT
    );
  }
}
