@use 'styles/colors.module.scss' as colors;
@use 'styles/typekit.module.scss' as typekit;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'src/styles/mixins.module.scss' as mixins;
@use 'src/styles/utils.module.scss' as utils;

.toast {
  @include mixins.flex-container(row, center, center, 0.6rem);
  min-height: 3rem;
  width: 100%;
  padding: 0.5rem 1rem;
  padding-right: 2rem;
  border-radius: 0.125rem;
  color: colors.$text-white;
  background: colors.$interactive-black;
  margin: utils.pxToRem(5);
  cursor: pointer;
  transition: transform 0.3s;

  @include breakpoints.media-query-for('small-tablet') {
    width: fit-content;
  }

  &:hover {
    transform: scale(1.05);
  }

  &__icon {
    width: 1rem;
    height: 1rem;
  }
}

.open-animation {
  animation-name: open-animate;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-duration: 0.4s;
}

@keyframes open-animate {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
