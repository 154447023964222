@use '/src/styles/colors.module.scss';
@use '/src/styles/mixins.module.scss';
@use 'styles/utils.module.scss' as utils;
@use 'styles/constants.module.scss' as constants;
@use 'styles/breakpoints.module.scss' as breakpoints;

.settings {
  @include mixins.flex-container($direction: column, $gap: utils.pxToRem(24));

  &__admin-roles {
    @include mixins.flex-container($direction: column, $gap: 1.5rem);
    background-color: colors.$interactive-white;
    padding: 1.5rem;
  }

  &__filter-options {
    background-color: colors.$interactive-white;
    min-width: 100%;

    @include breakpoints.media-query-for(constants.$SMALL_DESKTOP_BREAKPOINT) {
      min-width: fit-content;
    }
  }

  &__user-table {
    min-height: 32.5rem;
    width: 100%;
  }
}

.filters {
  @include breakpoints.media-query-for(constants.$LARGE_DESKTOP_BREAKPOINT) {
    width: 7.5rem;
  }

  &--roles {
    @include breakpoints.media-query-for(constants.$LARGE_DESKTOP_BREAKPOINT) {
      width: 5.5rem;
    }
  }
}
