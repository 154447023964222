@use 'styles/colors.module.scss' as *;
@use 'styles/breakpoints.module.scss' as *;
@use 'styles/utils.module.scss';
@use 'styles/animations.module.scss' as keyframes;

.modal {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: utils.pxToRem(2);
  background: $interactive-white;
  color: $text-black;
  width: 100%;
  position: relative;
  animation: 0.2s ease-out 0s slideInFromBottomFade;

  @include media-query-for('small-desktop') {
    width: utils.pxToRem(465);
  }

  &--closing {
    animation: 0.2s ease-out 0s slideOutFromBottomFade forwards;
  }

  &__overlay {
    padding: 0 1rem;

    &--closing {
      animation: 0.1s ease-out 0s fadeOut forwards;
    }
  }

  &__container {
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
  }

  &__information {
    justify-content: flex-start;
  }

  &__buttons {
    justify-content: flex-end;
    border-top: utils.pxToRem(1) solid $borders-strokes-border-grey;

    @include media-query-for('small-desktop') {
      flex-direction: row-reverse;
      justify-content: end;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  &__exit-icon {
    color: $interactive-kg-blue;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }

  &__description {
    align-self: stretch;
  }

  &__link {
    color: $interactive-kg-blue;
  }
}
