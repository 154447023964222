@use '/src/styles/colors.module.scss';

.login__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: colors.$interactive-kg-blue;

  &__icon {
    width: 6rem;
    height: 6rem;
  }

  &__input {
    > label {
      color: colors.$interactive-white;
    }
  }

  &__error_message {
    color: white;
    justify-content: center;
  }
}
