@use '/src/styles/colors.module.scss';
@use '/src/styles/breakpoints.module.scss';
@use '/src/styles/constants.module.scss';

.enddate {
  color: colors.$text-medium-grey;
  display: flex;
  gap: 0.125rem;

  &__days-left {
    color: colors.$interactive-kg-blue;
  }
}

.enddate-banner {
  @extend .enddate;
  @include breakpoints.media-query-for(constants.$MEDIUM_DESKTOP_BREAKPOINT) {
    display: none;
  }
}
