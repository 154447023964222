@use 'styles/colors.module.scss' as colors;
@use 'styles/typekit.module.scss' as typekit;
@use 'styles/breakpoints.module.scss' as breakpoints;
@use 'styles/utils.module.scss' as utils;
@use 'src/styles/mixins.module.scss' as mixins;

.swiper-wrapper {
  align-items: center;
  overflow: visible;
}

.swiper-container {
  overflow: visible;
}

.swiper-pagination-bullet {
  height: 0.75rem;
  width: 0.75rem;
  border: utils.pxToRem(1.5) solid colors.$text-white;
  opacity: 1;
  background-color: transparent;

  &-active {
    background-color: colors.$text-white;
  }
}

.pagination-container {
  position: absolute;
}
