@use '/src/styles/colors.module.scss';
@use '/src/styles/breakpoints.module.scss';
@use '/src/styles/mixins.module.scss';
@use '/src/styles/constants.module.scss';
@use '/src/styles/utils.module.scss';

$banner-max-width: utils.pxToRem(1328);

.banner {
  display: flex;
  flex-direction: column;
  background-color: colors.$background-white;
  width: 100%;

  @include breakpoints.media-query-for('small-desktop') {
    padding: 1.5rem 2rem 2rem;
  }

  @include breakpoints.media-query-for('medium-desktop') {
    padding: 1.5rem 3.5rem 2rem;
  }

  @include breakpoints.media-query-for('large-desktop') {
    padding-left: 0;
    padding-right: 0;
    width: fit-content;
    min-width: $banner-max-width;
  }

  &__main {
    border-bottom: constants.$BORDER-GREY;
    display: flex;
    background-color: colors.$interactive-white;
    height: fit-content;
    position: relative;

    @include breakpoints.media-query-for('small-desktop') {
      border: 0;
      width: 100%;
    }

    @include breakpoints.media-query-for('large-desktop') {
      max-width: $banner-max-width;
      padding: 0 1.5rem;
    }
  }

  &__content {
    @include mixins.flex-container(
      $direction: column,
      $justify-content: space-evenly,
      $gap: 1.5rem
    );
    flex-shrink: 0;
    min-height: utils.pxToRem(254);
    width: 60%;
    padding: 1rem 0 1rem 1rem;

    @include breakpoints.media-query-for('large-mobile') {
      width: 50%;
    }

    @include breakpoints.media-query-for('small-desktop') {
      width: 40%;
      padding: 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__title {
    margin-top: 0.5rem;
  }

  &__image-container {
    @include mixins.slope-image;
    display: flex;
    position: absolute;
    overflow: hidden;
    right: 0;
    height: 100%;
    width: 40%;

    @include breakpoints.media-query-for('large-mobile') {
      width: 50%;
    }

    @include breakpoints.media-query-for('small-desktop') {
      width: 60%;
    }

    @include breakpoints.media-query-for('large-desktop') {
      right: 1.5rem;
    }
  }

  &__gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(colors.$interactive-black, 0.75),
      rgba(colors.$interactive-black, 0)
    );
  }

  &__image {
    object-fit: cover;
    width: 100%;
  }

  &__countdown-container {
    position: absolute;
    right: 2rem;
    bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.25rem;
    width: pxToRem(425);
    color: colors.$interactive-white;
  }

  &__countdown {
    padding: 0;
  }

  &__countdown-title {
    align-self: flex-end;
    padding-right: 1rem;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem 1rem;
    gap: 1rem;
  }
}
