@use 'styles/colors.module.scss' as colors;
@use 'styles/breakpoints.module.scss' as *;
@use 'styles/utils.module.scss' as utils;
@use 'styles/mixins.module.scss' as mixins;
@use 'styles/z-index.module.scss' as z-index;

$image-height: 5.5rem;

.file-upload {
  @include mixins.flex-container(
    column,
    $align-items: flex-start,
    $gap: 0.25rem
  );
  align-self: stretch;

  &__label {
    align-self: stretch;
    text-align: left;
    color: colors.$text-medium-grey;
  }
}

.file-upload-container {
  @include mixins.flex-container(column, $gap: 0.25rem);
  width: 100%;
}

.file-selection-container {
  @include mixins.flex-container(column, center, center, 1rem);
  width: 100%;
  height: utils.pxToRem(230);
  padding: 2rem 6.25rem;
  background-color: colors.$interactive-light-grey;
  border-radius: 0.5rem;
  position: relative;

  &::before {
    content: '';
    width: calc(100% + utils.pxToRem(2.4));
    height: calc(100% + utils.pxToRem(2.4));
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23b8b8b8' stroke-width='2' stroke-dasharray='9' stroke-dashoffset='0' /%3e%3c/svg%3e");
    border-radius: 0.5rem;
    position: absolute;
    top: utils.pxToRem(-1.2);
    left: utils.pxToRem(-1.2);
  }

  &--error::before {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23cc0000' stroke-width='2' stroke-dasharray='9' stroke-dashoffset='0' /%3e%3c/svg%3e");
  }

  &--error {
    background-color: colors.$system-error-tint-red;
  }

  &__icon {
    height: 3rem;
    width: 3rem;
    color: colors.$interactive-black;
  }

  &__upload-btn {
    width: auto;
    align-self: auto;
    z-index: z-index.$z-index-file-upload-btn;
  }
}

.file-preview-container {
  @include mixins.flex-container(row, $gap: 1rem);
  outline: none;
  width: 100%;
  padding: 1rem 0rem;
  flex-wrap: wrap;

  @include media-query-for('small-desktop') {
    width: 100%;
    gap: 1.5rem;
    align-self: stretch;
  }
}

.display-text-container {
  @include mixins.flex-container(row, center, center, $gap: 0.5rem);
  width: utils.pxToRem(377);
  height: auto;
  text-align: center;
}

.confirmation-icon {
  width: utils.pxToRem(18);
  height: utils.pxToRem(18);
  color: colors.$system-success-green;
  path {
    color: colors.$interactive-white;
  }
}

.img-container {
  @include mixins.flex-container(
    column,
    $align-items: flex-start,
    $gap: 0.5rem
  );
  position: relative;

  &__img-style {
    width: utils.pxToRem(101);
    height: $image-height;
    border-radius: 0.5rem;
    object-fit: cover;
    margin-bottom: 1.5rem;
  }

  &__text-style {
    position: absolute;
    top: $image-height + 0.5rem;
    width: 100%;
    height: utils.pxToRem(18);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__remove-btn {
    position: absolute;
    color: colors.$interactive-kg-blue;

    circle {
      color: colors.$interactive-white;
    }
    right: utils.pxToRem(-11);
    top: -0.75rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.input-style {
  display: none;
}

.progress-bar-container {
  width: utils.pxToRem(279);
}

.file-preview-wrapper {
  height: auto;
  overflow: auto;
}

.text-container {
  @include mixins.flex-container(column);
  text-align: center;

  &__validation-text {
    color: colors.$text-medium-grey;
  }
}
